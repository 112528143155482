import { polyfill } from 'es6-promise'
polyfill()

import Vue from 'vue/dist/vue.esm'
import Vuex from 'vuex/dist/vuex.esm'
Vue.use(Vuex)

import { storeTranslations } from './_store_translations.js'
import { PRESELECTIONS, getFilterOptions, getFilterSelectionForStore } from '../helpers/filters-helper'
import { LOADING_STATES } from '../helpers/request-helper'

const SOURCES_COLOR_PRIORTIES =  [
  105, // A
  106, // C
  108, // F
  110, // O
  112, // W
  107, // D
  111, // R
  109, // I
  162, // U
  165, // X
  'unreported'
]

const getReportedFilter = filters => {
  const reported_by = filters.reported_by
  const reported_by_party = filters.reported_by_party

  return reported_by ? { reported_by } : { reported_by_party }
}

const getFilterOptionsFromState = (state, filterId) => {
  return filterId in state.filterOptions ?
    state.filterOptions[filterId] : []
}

const checkIfDashboardIsLoading = loadingStateById => {
  const loadingStates = [
    LOADING_STATES.loadingChart,
    LOADING_STATES.loadingFullList,
    LOADING_STATES.downloadingChart,
    LOADING_STATES.downloadingCsv
  ]
  let loading = false

  Object.keys(loadingStateById).forEach(compId => {
    if (loadingStates.indexOf(loadingStateById[compId]) >= 0) {
      loading = true
    }
  })

  return loading
}

export default new Vuex.Store({
  modules: {
    translations: storeTranslations
  },

  state: {
    appliedFilters: {},
    appliedGroupFilters: {},
    selectedFilters: {},
    selectedGroupFilters: {},
    filterOptions: {},
    filterConfigs: {},
    top_global_taxonomic_params: {},
    sourcesColorPalette: {},

    isDashboardOverlayShown: true,
    page: '',
    isDashboardLoading: false,
    loadingStateById: {}
  },

  actions: {
    updateFilterConfigs({ commit }, configs) {
      commit('updateFilterConfigs', configs)
    },

    updateComponentLoadingState({ commit }, payload) {
      commit('updateComponentLoadingState', payload)
    },

    initFilterOptions({ commit, dispatch }, options) {
      commit('initFilterOptions', options)
      commit('mergeSelectedFilters', PRESELECTIONS)
      dispatch('selectInitialDateRangeFilters')
    },

    resetFilters ({ commit, dispatch, state }) {
      const newFilters = {
        ...PRESELECTIONS,
        ...getReportedFilter(state.appliedFilters)
      }

      commit('updateSelectedFilters', newFilters)
      commit('updateSelectedGroupFilters', {})
      dispatch('selectInitialDateRangeFilters')
      commit('applyFilters')
    },

    selectInitialDateRangeFilters ({ dispatch, state }) {
      ['time_range_start', 'time_range_end'].forEach(filterId => {
        const options = getFilterOptionsFromState(state, filterId)
        const initDateRange = 7
        const option = options[filterId === 'time_range_start' ? initDateRange - 1 : 0]


        dispatch('updateStoreSelections', { filterId, options: option })
      })
    },

    updateStoreSelections({ commit, state }, { filterId, options }) {
      if (state.filterConfigs[filterId].isAutocomplete) {
        commit('updateFilterOptionsWithoutWatching', {
          filterId,
          options
        })
      }

      const selectionForStore = getFilterSelectionForStore(options)

      commit('selectFilter', {
        id: filterId,
        selection: selectionForStore.singular
      })

      if (selectionForStore.group) {
        commit('selectGroupFilter', {
          id: filterId,
          selection: selectionForStore.group
        })
      }
    }
  },

  mutations: {
    updateComponentLoadingState (state, payload) {
      Vue.set(
        state.loadingStateById,
        payload.id,
        payload.loadingState
      )

      state.isDashboardLoading = checkIfDashboardIsLoading(state.loadingStateById)
    },

    updateFilterConfigs (state, configs) {
      configs.forEach(filterConfig => {
        Vue.set(
          state.filterConfigs,
          filterConfig.id,
          filterConfig
        )
      })
    },

    initFilterOptions (state, options) {
      Object.keys(state.filterConfigs).forEach(filterId => {
        const filterConfig = state.filterConfigs[filterId]

        if (filterConfig.optionsId in options) {
          Vue.set(
            state.filterOptions,
            filterConfig.id,
            getFilterOptions(filterConfig, options)
          )
        }
      })
    },

    updateFilterOptionsWithoutWatching (state, payload) {
      state.filterOptions[payload.filterId] = payload.options
    },

    setSourcesColorPalette (state, sources) {
      sources.forEach(source => {
        state.sourcesColorPalette[source.id] = SOURCES_COLOR_PRIORTIES.indexOf(source.id)
      })
    },

    selectFilter (state, filterPayload) {
      state.selectedFilters = {
        ...state.selectedFilters,
        [filterPayload.id]: filterPayload.selection
      }
    },

    mergeSelectedFilters (state, filters) {
      state.selectedFilters = {
        ...state.selectedFilters,
        ...filters
      }
    },

    updateSelectedFilters (state, filters) {
      state.selectedFilters = { ...filters }
    },

    updateSelectedGroupFilters (state, filters) {
      state.selectedGroupFilters = { ...filters }
    },

    selectGroupFilter (state, filterPayload) {
      state.selectedGroupFilters = {
        ...state.selectedGroupFilters,
        [filterPayload.id]: filterPayload.selection
      }
    },

    applyFilter (state, filterPayload) {
      state.appliedFilters = {
        ...state.appliedFilters,
        [filterPayload.id]: filterPayload.selection
      }
    },

    applyFilters (state) {
      state.appliedFilters = { ...state.selectedFilters }
      state.appliedGroupFilters = { ...state.selectedGroupFilters }
    },

    updateChartParams (state, filterPayload) {
      state[filterPayload.id + '_params'] = filterPayload.params
    },

    updateIsDashboardOverlayShown (state, isShown) {
      state.isDashboardOverlayShown = isShown
    },

    updatePage (state, page) {
      state.page = page
    }
  }
})
