<template>
  <div class="tooltip">
    <div
      v-if="onHover"
      tabindex="0"
      :aria-describedby="id"
      :aria-expanded="isActive"
      class="tooltip__trigger"
      @mouseenter="open"
      @mouseleave="close"
    >
      <slot />
    </div>

    <div 
      v-else
      v-touch="toggleTooltip"
      tabindex="0"
      :aria-describedby="id"
      :aria-expanded="isActive"
      class="tooltip__trigger"
      @click:prevent="toggleTooltip"
      @keypress.enter="toggleTooltip"
      @keypress.space.prevent="toggleTooltip"
    >
      <slot />
    </div>

    <div
      v-show="isActive"
      :id="id"
      role="tooltip"
      class="tooltip__target"
      v-html="text"
    />
  </div>
</template>

<script>
import { getInputs, isTabBackward, isTabForward } from '../../helpers/focus-helpers'
import mixinPopupCloseListener from '../../mixins/mixin-popup-close-listeners'

export default {
  name: 'Tooltip',

  mixins: [mixinPopupCloseListener({closeCallback: 'close'})],

  props: {
    text: {
      type: String,
      required: true
    },
    onHover: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      isActive: false,
      id: `tooltip_${this._uid}`
    }
  },

  mounted () {
    const tooltipTrigger = this.$el.querySelector('.tooltip__trigger')

    if (this.onHover) {
      tooltipTrigger.addEventListener('blur', this.close)
      tooltipTrigger.addEventListener('focus', this.open)
    } else {
      const tabElements = getInputs(this.$el.querySelector('.tooltip__target'))

      if (tabElements.length) {
        tabElements[tabElements.length - 1].addEventListener('keydown', e => {
          if (isTabForward(e)) { this.close() }
        })
        tooltipTrigger.addEventListener('keydown', e => {
          if (isTabBackward(e)) { this.close() }
        })
      } else {
        tooltipTrigger.addEventListener('blur', this.close)
      }
    }  
  },

  methods: {
    close () {
      this.isActive = false
    },

    open () {
      this.isActive = true
    },

    toggleTooltip () {
      this.isActive ? this.close() : this.open()
    }
  }
}  
</script>