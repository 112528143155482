<template>
  <span 
    class="component-header__buttons flex flex-no-shrink"
  >
    <button 
      class="component-header__button--download relative"
      :class="{'active': isDownloading, 'disabled': disabled}"
      :aria-disabled="isDownloading"
      @click="downloadPdf"
    >
      {{ translations.download }}
      <div 
        v-if="isDownloading"
        class="loader"
      />
    </button>
    <button
      v-if="hasFullList"
      class="component-header__button--list"
      :class="{'disabled': disabled}"
      aria-haspopup="dialog"
      @click="openFullList"
    >
      {{ translations.full_list }}
    </button>
  </span>
</template>

<script>
import { LOADING_STATES } from '../../helpers/request-helper'
export default {
  props: {
    componentId: {
      type: String,
      required: true
    },
    hasFullList: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    isDownloading () {
      return this.$store.state.loadingStateById[this.componentId] === 
        LOADING_STATES.downloadingChart
    },

    translations () {
      return this.$store.state.translations.translations.buttons
    }
  },

  methods: {
    downloadPdf () {
      if (!this.disabled) { 
        this.$eventHub.$emit('download-chart', this.componentId)
      }
    },

    openFullList () {
      if (!this.disabled) { 
        this.$eventHub.$emit('open-full-list', this.componentId)
      }
    }
  }
}
</script>