<template>
  <div class="component__loading-overlay relative">
    <div class="item-center text-center">
      <template v-if="loading">
        <h2>{{ translations.loading }}...</h2>
        <div class="loader component__loader" />
      </template>
      <p v-else-if="error">
        {{ translations.request_error }}
      </p>
      <p v-else>
        {{ translations.no_results }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },

    error: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    translations () {
      return this.$store.state.translations.translations.misc
    }
  }
}
</script>

