<template>
  <div class="chart-download">
    <template v-if="params">
      <component
        :is="params.type"
        :component-id="params.id"
        :chart-smallprint="params.chart_smallprint"
        :title="params.title"
        :data="JSON.parse(params.data)"
        :color-palette="params.color_palettes"
        :x-start="Number(params.time_range_start)"
        :x-end="Number(params.time_range_end)"
        :units="params.units"
        :tabs="params.tabs"
      />

      <div class="chart-download__reported-by">
        {{ params.reported_by_text }}
      </div>
      <div class="chart-download__params item-padding">
        <h2 class="no-margin--top">
          {{ params.params_title }}
        </h2>
        <div
          v-if="isFullWidth(params.first_filter.label)"
          class="chart-download__param"
        >
          <h3 class="chart-download__param-label">
            {{ params.first_filter.label }}
          </h3>
          <p class="chart-download__param-value">
            <span
              v-if="isTaxonPage(params.first_filter.label)"
              v-html="`${params.first_filter.selection}`" >
            </span>
            <span
              v-if="!isTaxonPage(params.first_filter.label)">
              {{ params.first_filter.selection }}
            </span>
          </p>
        </div>

        <div class="flex">
          <template
            v-for="(col, index) in params.ordered_filters"
          >
            <span
              v-if="!isFullWidth(col[0].label)"
              :key="`col-${index}`"
              class="chart-download__params-col flex-1-quarter"
            >
              <div
                v-for="filter in col"
                :key="`filter-${filter.label}`"
                class="chart-download__param"
              >
                <h3 class="chart-download__param-label">
                  {{ filter.label }}
                </h3>
                <p class="chart-download__param-value">
                  {{ filter.selection }}
                </p>
              </div>
            </span>
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import ChartCircularPackingComponent from '../chart-circular-packing-component/ChartCircularPackingComponent'
import ChartRowComponent from '../chart-row-component/ChartRowComponent'
import ChartLineComponent from '../chart-line-component/ChartLineComponent'
import MapInfographic from '../map-infographic/MapInfographic'

export default {
  components: {
    ChartCircularPackingComponent,
    ChartRowComponent,
    ChartLineComponent,
    MapInfographic
  },

  data () {
    return {
      params: ''
    }
  },

  mounted () {
    const interval = setInterval(() => {
      if (window.params) {
        this.params = window.params
        clearInterval(interval)
      }
    }, 1000)
  },

  methods: {
    isFullWidth (label) {
      return label === 'Taxon' || label === 'Country'
    },

    isTaxonPage (label) {
      return label == 'Taxon'
    }
  }
}
</script>
