<template>
  <div
    class="v-select v-select--search relative"
    :class="{'disabled': isDisabled, 'active': isActive}"
  >
    <input
      :id="config.id"
      v-model="selectedInternal.name"
      type="hidden"
      :name="config.id"
    >

    <div
      v-if="config.label"
      class="v-select__label"
    >
      <label
        :for="toggleId"
        class="v-select__selection"
      >{{ config.label }}</label>
      <slot name="label-icon" />
    </div>

    <p 
      v-if="config.helpText"
      class="v-select__help-text"
      v-html="config.helpText"
    />

    <div class="v-select__click-area">
      <div class="v-select__search relative">
        <label
          class="screen-reader"
          :for="searchId"
        >
          {{ getSearchLabel(config) }}
        </label>
        <input
          :id="searchId"
          v-model="searchTerm"
          class="v-select__search-input"
          type="text"
          role="combobox"
          aria-haspopup="listbox"
          aria-autocomplete="list"
          :aria-expanded="showOptions.toString()"
          :aria-owns="dropdownId"
          :aria-activedescendant="highlightedOptionId" 
          :placeholder="placeholder"
          :disabled="isDisabled"
          @focus="openSelect"
        >

        <span class="v-select__search-icons">
          <span
            v-show="showSearchIcon"
            class="v-select__search-icon"
          />
          <button 
            v-show="showResetIcon"
            :id="searchResetId"
            class="v-select__search-icon--reset hover--pointer"
            @click="resetSearchTerm"
          />
          <span 
            class="drop-arrow v-select__drop-arrow arrow-svg hover--pointer"
            @click="toggleSelect"
          />
        </span>
      </div>

      <ul 
        v-show="showOptions" 
        :id="dropdownId" 
        role="listbox" 
        class="v-select__dropdown"
      >
        <li
          v-for="(option, index) in filteredOptions"
          v-show="matchesSearchTerm(option)"
          :id="getOptionInputId(option)"
          :key="option.id"
          :class="['v-select__option hover--pointer', conditionalOptionClasses(option, index)]"
          :aria-disabled="option.disabled"
          role="option"
          :aria-selected="isHighlighted(index).toString()"
          @click="selectOption(option)"
        >
          {{ option.name }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import mixinSelectShared from '../../mixins/mixin-select-shared'

const UNDEFINED_ID = '__UNDEFINED__'
const UNDEFINED_OBJECT = { id: UNDEFINED_ID, name: 'Select option' }

export default {
  mixins: [
    mixinSelectShared
  ],

  props: {
    config: {
      required: true,
      type: Object
    },
    options: {
      default: () => [],
      type: Array
    },
    selected: {
      type: Object,
      default: () => UNDEFINED_OBJECT
    }
  },

  data () {
    return {
      selectedInternal: null,
    }
  },

  computed: {
    filteredOptions () {
      return this.options.filter(option => this.matchesSearchTerm(option))
    }
  },

  watch: {
    selected (newSelectedOption) {
      this.selectedInternal = newSelectedOption === null ?
        UNDEFINED_OBJECT :
        newSelectedOption
      this.setSearchTermToSelected()
    }
  },

  methods: {
    openSelect () {
      this.searchTerm = ''
      this.isActive = true
    },

    closeSelect () {
      this.setSearchTermToSelected()
      this.resetHighlightedIndex()
      this.isActive = false
    },

    initializeSelectedInternal () {
      if (this.selected === null) {
        this.selectedInternal = UNDEFINED_OBJECT
      } else {
        this.selectedInternal = this.selected
        this.setSearchTermToSelected()
      }
    },

    isSelected (option) {
      return option.id === this.selectedInternal.id
    },

    selectOption (option) {
      if (!option.disabled) {
        this.selectedInternal = option
        this.closeSelect()
        document.activeElement.blur()
        this.emitSelection()
      }
    },

    resetSearchTerm () {
      this.$el.querySelector('#' + this.searchId).focus()
      this.searchTerm = ''
    },

    setSearchTermToSelected () {
      this.searchTerm = this.selectedInternal.name
    }
  }
}
</script>