<template>
  <div class="flex flex-wrap flex-h-between">
    <template v-for="comp in components">
      <component
        :is="comp.type"
        :key="comp.id"
        :component-id="comp.id"
        :title="comp.title"
        :data="getComponentData(comp)"
        :tabs="comp.tabs"
        :color-palette="getColorPalette(comp)"
        :is-half-width="isCompHalfWidth(comp)"
        :loading-state="getLoadingState(comp)"
        :x-start="selectedFilters.time_range_start"
        :x-end="selectedFilters.time_range_end"
        :chart-smallprint="getChartSmallPrint(comp)"
        @update:selected-tab="updateSelectedTab(comp, $event)"
      />
    </template>
  </div>
</template>

<script>
import ChartCircularPackingComponent from '../chart-circular-packing-component/ChartCircularPackingComponent'
import ChartRowComponent from '../chart-row-component/ChartRowComponent'
import ChartLineComponent from '../chart-line-component/ChartLineComponent'
import MapInfographic from '../map-infographic/MapInfographic'
import mixinStoreExplore from '../../mixins/mixin-store-explore'

import { fetchComponentData, fetchChartDownload, downloadChart, LOADING_STATES } from '../../helpers/request-helper'

// Tab = {id: String, label: String}
export default {
  name: 'Components',

  components: { ChartCircularPackingComponent, ChartRowComponent, ChartLineComponent, MapInfographic },

  mixins: [mixinStoreExplore],

  props: {
    components: { // {id: String, type: String, title: String, tabs?: Tab[] }
      type: Array,
      default: () => []
    },
    colorPalettes: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      componentsData: {},
      isDownloadingById: {},
      latestRequestNumById: {}
    }
  },

  computed: {
    loadingStateById () {
      return this.$store.state.loadingStateById
    },

    isDashboardLoading () {
      return this.$store.state.isDashboardLoading
    },

    translations () {
      return this.$store.state.translations.translations
    },
  },

  watch: {
    appliedFilters () {
      this.fetchComponentsData()
    }
  },

  created () {
    this.setEventHubListeners()
  },

  methods: {
    setEventHubListeners () {
      this.$eventHub.$on('download-chart', componentId => {
        this.chartDownload(componentId)
      })
      this.$eventHub.$on('open-full-list', componentId => {
        this.openFullList(componentId)
      })
    },

    openFullList (componentId) {
      const component = this.getComponentFromId(componentId)

      this.$eventHub.$emit('open-full-list-modal', {
        component: component,
        params: {
          ...this.getParamsForComponent(component),
          json_filters_for_display: JSON.stringify(this.getAppliedSelectionsForDisplay(component))
        },
        units: this.getSelectionStringForDownload('unit_id')
      })
    },

    getComponentData (comp) {
      return this.componentsData[comp.id] ? this.componentsData[comp.id] : []
    },

    getComponentFromId (id) {
      return this.components.filter(comp => comp.id === id)[0]
    },

    updateSelectedTab (comp, tabId) {
      this.$store.commit(
        'updateChartParams',
        {
          id: comp.id,
          params: this.getCompParamsAfterTabUpdate(comp, tabId)
        }
      )
      this.fetchComponentsData([{id: comp.id}])
    },

    getCompParamsAfterTabUpdate (comp, tabId) {
      return {
        [comp.tab_param_id]: tabId
      }
    },

    fetchComponentsData (componentsToUpdate) {
      if (this.isDashboardOverlayShown) { return }

      const components = componentsToUpdate ? componentsToUpdate : this.components

      components.forEach(comp => {
        this.updateCompLoadingState(comp, LOADING_STATES.loadingChart)
        const newRequestNum =
          this.latestRequestNumById[comp.id] =
          this.getNewRequestNum(comp)
        const params = {
          ...this.getParamsForComponent(comp),
          limit: 5
        }

        fetchComponentData(
          this.$store.state.translations.locale.id,
          comp.id,
          params,
          this.getComponentRequestHandler(comp, newRequestNum, true),
          this.getComponentRequestHandler(comp, newRequestNum, false)
        )
      })
    },

    getComponentRequestHandler (comp, newRequestNum, isSuccess) {
      return res => {
        const compData = isSuccess ? this.getDataWithIds(comp, res.data) : null

        if (this.latestRequestNumById[comp.id] === newRequestNum) {
          this.$set(this.componentsData, comp.id, compData)
          this.updateCompLoadingState(comp,
            isSuccess ? LOADING_STATES.loaded : LOADING_STATES.errorChart
          )
        }
      }
    },

    getParamsForComponent (comp) {
      return {
        ...this.appliedFilters,
        ...this.getComponentSpecificParams(comp.id),
      }
    },

    getNewRequestNum (comp) {
      const lastRequestNum = this.latestRequestNumById[comp.id]

      return lastRequestNum ? lastRequestNum + 1 : 1
    },

    getDataWithIds (comp, data) {
      return data.map(d => {
        const dataId = d.id ? d.id : d.name

        if (comp.type === 'chart-line-component') {
          d.color = this.getLineColor(comp, dataId)
        }

        return { ...d, id: dataId}
      })
    },

    getLineColor (comp, lineId) {
      return {
        line: this.getColorPalette(comp)[
          this.colorPaletteIndexBySourceId[lineId]
        ],
        fill: '#ffffff',
        text: '#000000'
      }
    },

    getLoadingState (comp) {
      return comp.id in this.loadingStateById ? this.loadingStateById[comp.id] : LOADING_STATES.loadingChart
    },

    chartDownload (componentId) {
      const comp = this.getComponentFromId(componentId)

      this.updateIsDownloading(comp, true)

      fetchChartDownload({
        locale: this.$store.state.translations.locale.id,
        chartDownloadParams: this.getDownloadParams(comp),
        csvQueryParams: this.getParamsForComponent(comp),
        successCallback: this.getOnChartDownloadSuccess(comp),
        failureCallback: this.getOnChartDownloadFailure(comp)
      })
    },

    getOnChartDownloadSuccess (comp) {
      return res => {
        if (this.$ga) {
          this.$ga.event('Chart', 'Download', `${window.location.pathname}: ${comp.id}`)
        }

        downloadChart(res, this.translations.chart_download.chart)
        this.updateIsDownloading(comp, false)
      }
    },

    getOnChartDownloadFailure (comp) {
      return err => {
        console.log(err)
        this.updateIsDownloading(comp, false)
      }
    },

    updateIsDownloading (comp, isDownloading) {
      this.$store.dispatch('updateComponentLoadingState', {
        id: comp.id,
        loadingState: isDownloading ?
          LOADING_STATES.downloadingChart : LOADING_STATES.loaded
      })
    },

    getDownloadParams (comp) {
      const params = {
        data: JSON.stringify(this.getComponentData(comp)),
        appliedFiltersForChart: JSON.stringify(this.getAppliedSelectionsForDisplay(comp)),
        id: comp.id,
        title: comp.title,
        type: comp.type,
        color_palettes: this.getColorPalette(comp)
      }

      const tabId = comp.tab_param_id

      if (tabId) {
        params.tab_param_id = tabId
        params[tabId] = this.getSelectedTab(comp)
      }

      return params
    },

    getSelectionStringForDownload(filterId) {
      const selection = this.getAppliedSelectionFromStoreSelection(filterId)

      if (Array.isArray(selection)) {
        return selection.map(o => o.nameForChartDownload).join(', ')
      }

      return selection.nameForChartDownload
    },

    getColorPalette (comp) {
      return comp.type === 'chart-line-component' ?
        this.colorPalettes.all :
        this.colorPalettes.five
    },

    isDownloading (comp) {
      return comp.id in this.isDownloadingById ? this.isDownloadingById[comp.id] : false
    },

    isCompHalfWidth (comp) {
      return Boolean(comp.half_width)
    },

    getChartSmallPrint (comp) {
      let disclaimer = ''

      if (comp.type === 'map-infographic') {
        disclaimer += this.translations.map_disclaimer + '<br><br>'
      }

      disclaimer += `${this.translations.site_title} ${new Date().getFullYear()}. ${this.translations.filters_applied}`

      return disclaimer
    }
  }
}
</script>
