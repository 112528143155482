<template>
  <header class="dashboard-header gutters">
    <h1 class="dashboard-header__title">
      {{ headingTranslations.title }}
    </h1>
    <div class="dashboard-header__filters">
      {{ headingTranslations.filters_applied }}
      <tooltip
        :text="tooltipHtml"
        :on-hover="false"
      >
        <span class="dashboard-header__tooltip-toggle">
          {{ headingTranslations.see_list }}
        </span>
      </tooltip>
    </div>
  </header>
</template>

<script>
import mixinStoreExplore from '../../mixins/mixin-store-explore'
import Tooltip from '../tooltip/Tooltip'

export default {
  components: {
    Tooltip
  },

  mixins: [mixinStoreExplore],

  props: {
    headingTranslations: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    tooltipHtml () {
      const selections = this.getAppliedSelectionsForDisplay()
      let html = ''

      Object.keys(selections).forEach(selectionId => {
        const selection = selections[selectionId]

        html += `<p class="dashboard-header__tooltip-text"><strong>${selection.label}:</strong> ${selection.selection}</p>`
      })

      return html
    },

    translations () {
      return this.$store.state.translations.translations.misc
    }
  },
}
</script>
