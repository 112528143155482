<template>
  <div 
    :id="componentId"
    :class="['component', {'component--half-width': isHalfWidth}]"
  >
    <component-header
      :title="title"
      :component-id="componentId"
      :disabled="showOverlay"
    />
    <div class="component__chart-legend-wrapper">
      <div class="component__chart">
        <chart-row 
          :rows="data"
          units=""
          :active-label-index="activeLabelIndex"
          :color-palette="colorPalette"
        />
        <div 
          class="component__disclaimer" 
          v-html="chartSmallprint" 
        />
      </div>

      <component-legend 
        :items="data"
        :units="units"
        :color-palette="colorPalette"
        @item-mouseover="onItemMouseover"
        @item-mouseleave="onItemMouseleave"
      />
      <component-loading-overlay
        v-if="showOverlay"
        :loading="isChartLoading"
        :error="hasChartError"
      />
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import ChartRow from '../charts/ChartRow'
import ComponentHeader from '../component/ComponentHeader'
import ComponentLegend from '../component/ComponentLegend'
import ComponentLoadingOverlay from '../component/ComponentLoadingOverlay'

import mixinComponentLoadingOverlay from '../../mixins/mixin-component-loading-overlay'

export default {
  name: 'ChartRowComponent',

  components: { ChartRow, ComponentHeader, ComponentLegend, ComponentLoadingOverlay },

  mixins: [mixinComponentLoadingOverlay()],

  props: {
    componentId: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: ''
    },
    data: { // {id: String, name: String, value: Number}[]
      type: Array,
      required: true
    },
    isHalfWidth: {
      type: Boolean,
      default: false
    },
    colorPalette: {
      type: Array,
      default: () => []
    },
    units: {
      type: String,
      default: ''
    },
    chartSmallprint: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      activeLabelIndex: -1
    }
  },

  methods: {
    onItemMouseover (e) {
      this.activeLabelIndex = e.index
    },

    onItemMouseleave () {
      this.activeLabelIndex = -1
    }
  }
}
</script>
