const OPTION_NULL = { id: 'null', iso2: 'null', name: 'Null' }
const EU_COUNTRY_ISOs = ['AT', 'BE', 'BG', 'CY', 'CZ', 'DE', 'DK', 'EE', 'ES', 'FI', 'FR', 'GR', 'HR', 'HU', 'IE', 'IT', 'LT', 'LU', 'LV', 'MT', 'NL', 'PL', 'PT', 'RO', 'SE', 'SI', 'SK']
const EU_ID = '_EU_'
const EU_COUNTRY_OPTION = {id: EU_ID, iso2: EU_ID,  name: 'EU-27', queryValues: [], isGroup: true}

export const MAX_DATE_RANGE = 10

export const PRESELECTIONS = {
  unit_id: 'items',
  origin_ids: ['direct']
}

export const isCountryFilter = filterId =>
  ['countries', 'importers', 'exporters', 'origins'].indexOf(filterId) >= 0

export const getFilterOptions = (filterConfig, options, sortAlphabetically=false) => {
  const optionsId = filterConfig.optionsId
  const filterOptions = options[optionsId].map(mapOption(filterConfig))

  if (optionsId === 'years') {
    return filterOptions.sort((a,b) => b.id - a.id)
  } else if (isCountryFilter(optionsId)) {
    filterOptions.push(getEuCountryOption(filterOptions))
  }

  if (sortAlphabetically || isCountryFilter(optionsId)) {
    return filterOptions.sort((a,b) => a.name.localeCompare(b.name))
  }

  return filterOptions
}

const getEuCountryOption = filterOptions => {
  const option = {...EU_COUNTRY_OPTION}

  option.queryValues = EU_COUNTRY_ISOs.map(iso2 =>
    filterOptions.filter(op => op.iso2 === iso2)[0].id
  )

  return option
}

const mapOption = filterConfig => option => {
  const clonedOption = {...option}

  if (clonedOption.id === null || clonedOption.id === '') {
    return OPTION_NULL
  }

  clonedOption.name = getFilterOptionDisplayName(filterConfig.optionsId, clonedOption)
  clonedOption.nameForChartDownload = clonedOption[filterConfig.chartDownloadKey || 'name']
  clonedOption.queryValue = clonedOption[filterConfig.queryValueKey || 'id']

  return clonedOption
}

export const getFilterOptionDisplayName = (optionsId, option) => {
  if (['sources', 'purposes', 'terms'].indexOf(optionsId) >= 0) {
    if (option.id !== 'unreported') {
      return `${option.name} (${option.code})`
    }
  }

  return option.name
}

export const pushIfUnique = (array, elements) => {
  if (Array.isArray(elements)) {
    const additionalElements = elements.filter(e => array.indexOf(e) < 0)

    array.push(...additionalElements)
  } else if (array.indexOf(elements) < 0) {
    array.push(elements)
  }
}

export const getFilterSelectionForStore = optionSelection => {
  let selectionForStore = {}

  if (Array.isArray(optionSelection)) {
    selectionForStore = getSelectionArraysForStore(optionSelection)
  } else {
    selectionForStore.singular = optionSelection.queryValue
  }

  return selectionForStore
}

const getSelectionArraysForStore =  optionSelection => {
  const selectionsForStore = { singular: [], group: [] }

  optionSelection.forEach(s => {
    if (s.isGroup) {
      selectionsForStore.group.push(s.queryValue) //Does not support nested 'group filters'
      pushIfUnique(selectionsForStore.singular, s.queryValues)
    } else {
      pushIfUnique(selectionsForStore.singular, s.queryValue)
    }
  })

  return selectionsForStore
}
