<template>
  <div
    v-if="lines"
    class="chart--line"
  >
    <div 
      class="chart--line__ie-wrapper"
    >
      <svg
        width="100%"
        height="100%"
        :viewBox="`${-originToSVGLeft} ${-originToSVGTop} ${svgWidth} ${svgHeight}`"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="xMidYMid"
        overflow="visible"
        class="chart__svg"
      >

        <polyline
          :points="axisPolylinePoints"
          :stroke="axisColor"
          fill="none"
          stroke-width="1"
        />

        <text
          v-for="(y, index) in yAxis"
          :key="getVForKey('y-axis', index)"
          :x="-originToYAxisLabels" 
          :y="y.coord"
          text-anchor="end"
          :font-size="fontSize"
          :transform="`translate(0 ${0.35 * fontSize})`"
        >{{ y.labelText }}</text>

        <text
          v-for="(x, index) in xAxis"
          :key="getVForKey('x-axis', index)"
          :x="x.coord" 
          :y="originToXAxisLabels" 
          :font-size="fontSize"
          text-anchor="middle"
          :transform="`translate(0 ${fontSize/2})`"
        >{{ x.labelText }}</text>

        <chart-line-dataset 
          v-for="(line, index) in lines"
          :key="getVForKey('chart-line-dataset', index)"
          :path="getPath(line.datapoints)"
          :labels="getDatapointLabels(line)"
          :color="getLineColor(line)"
          @toggle-hover-label="toggleHoverLabel($event, index)"
        />

        <g
          v-show="activeHoverLabel"
          id="chartline-marker"
          :key="getVForKey('hover-label')"
          :transform="hoverLabelTransform"
        >
          <chart-datapoint-label :options="chartDatapointLabelOptions" />
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
import ChartLineDataset from './ChartLineDataset'
import ChartDatapointLabel from './ChartDatapointLabel'
import mixinIds from '../../mixins/mixin-ids'

const X_AXIS_TO_LABEL_PADDING = 25
const Y_AXIS_TO_LABEL_PADDING = 25
const X_AXIS_TO_SVG_PADDING = 60
const Y_AXIS_TO_SVG_PADDING = 140
const X_AXIS_TO_PLOT_PADDING = 0
const Y_AXIS_TO_PLOT_PADDING = 50
const OUTER_PLOT_TO_SVG_PADDING = 30

const DEFAULT_FONT_SIZE = 18

const DEFAULT_BACKGROUND_COLOR = 'transparent'
const DEFAULT_AXIS_COLOR = '#000'
const DEFAULT_PLOT_BACKGROUND_COLOR = 'transparent'
const DEFAULT_LINE_COLOUR = {
  line: '#000',
  fill: '#000',
  text: '#ccc'
}

const DEFAULT_SVG_WIDTH = 800
const DEFAULT_SVG_HEIGHT = 400

const DEFAULT_X_AXIS_CONFIG = {
  precision: 4,
  axisMarks: null
}
const DEFAULT_Y_AXIS_CONFIG = {
  precision: 2,
  axisMarks: 5
}

export default {
  name: 'ChartLine',

  components: { ChartLineDataset, ChartDatapointLabel },

  mixins: [mixinIds],

  props: {
    lines: {
      type: Array, // Line[]
      required: true
    },
    options: { // {[this.$data]: See defaults}
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      xAxisToPlotPadding: X_AXIS_TO_PLOT_PADDING,
      yAxisToPlotPadding: Y_AXIS_TO_PLOT_PADDING,
      xAxisToLabelPadding: X_AXIS_TO_LABEL_PADDING,
      yAxisToLabelPadding: Y_AXIS_TO_LABEL_PADDING,
      xAxisToSVGPadding: X_AXIS_TO_SVG_PADDING,
      yAxisToSVGPadding: Y_AXIS_TO_SVG_PADDING,
      outerPlotToSVGPadding: OUTER_PLOT_TO_SVG_PADDING,

      fontSize: DEFAULT_FONT_SIZE,

      axisColor: DEFAULT_AXIS_COLOR,
      backgroundColor: DEFAULT_BACKGROUND_COLOR,
      plotBackgroundColor: DEFAULT_PLOT_BACKGROUND_COLOR,

      svgWidth: DEFAULT_SVG_WIDTH,
      svgHeight: DEFAULT_SVG_HEIGHT,

      xAxisConfig: {...DEFAULT_X_AXIS_CONFIG},
      yAxisConfig: {...DEFAULT_Y_AXIS_CONFIG},

      activeHoverLabel: null,
      chartDatapointLabelOptions: {},
    }
  },

  computed: {
    hoverLabelTransform () {
      if (this.activeHoverLabel) {
        return `translate(${this.activeHoverLabel.x}, ${this.activeHoverLabel.y})`
      }

      return ''
    },

    yAxis () { return this.lines.length ? this.getAxis('y') : [] },

    xAxis () { return this.lines.length ? this.getAxis('x') : [] },

    plotHeight () { return this.svgHeight - 2 * this.xAxisToPlotPadding - this.xAxisToSVGPadding - this.outerPlotToSVGPadding },

    plotWidth () { return this.svgWidth - 2 * this.yAxisToPlotPadding - this.yAxisToSVGPadding - this.outerPlotToSVGPadding },

    originToSVGLeft () { return this.yAxisToPlotPadding + this.yAxisToSVGPadding },

    originToSVGTop () { return this.xAxisToPlotPadding + this.outerPlotToSVGPadding },

    originToYAxisLabels () { return this.yAxisToPlotPadding + this.yAxisToLabelPadding },

    originToXAxisLabels () { return this.plotHeight + this.xAxisToPlotPadding + this.xAxisToLabelPadding },

    axisPolylinePoints () { 
      return `
        ${-this.yAxisToPlotPadding},${-this.xAxisToPlotPadding} 
        ${-this.yAxisToPlotPadding},${this.plotHeight + this.xAxisToPlotPadding} 
        ${this.plotWidth + this.yAxisToPlotPadding},${this.plotHeight + this.xAxisToPlotPadding}
      ` 
    }
  }, 

  watch: {
    lines () {
      this.setMaxMin()
    },

    options () {
      this.mergeDefaults()
    }
  },

  created () {
    this.mergeDefaults()
    this.setMaxMin()
  },

  methods: {
    toggleHoverLabel (label) {
      if (label === null) {
        this.resetHoverLabel()
      } else {
        if (this.activeHoverLabel && this.activeHoverLabel.markerId === label.markerId) { return }

        this.activeHoverLabel = label
        this.chartDatapointLabelOptions = {
          x: label.x, 
          y: label.y, 
          text: label.value, 
          indexText: label.code,
          color: label.color
        }
      }
    },

    resetHoverLabel () {
      this.activeHoverLabel = null
    },

    setMaxMin () {
      this.xAxisConfig.min = this.getMinMax('min', 'x')
      this.xAxisConfig.max = this.getMinMax('max', 'x')
      this.yAxisConfig.min = 0
      this.yAxisConfig.max = this.getMinMax('max', 'y')
    },

    mergeDefaults() {
      if (this.options === undefined) { return }

      Object.keys(this.$data).forEach(key => {
        const currentValue = this[key]
        
        if (typeof currentValue === 'object' ) {
          this[key] = {
            ...currentValue, 
            ...this.options[key]
          }
        } else {
          this[key] = this.options[key]
        }
      })
    },

    getPath(dataset) {
      let path = ''
      
      dataset.forEach((point, index) => {
        const command = index == 0 ? 'M' : 'L'

        path += ` ${command} ${this.normaliseX(point.x)} ${this.normaliseY(point.y)}`
      })

      return path
    },


    getAxis (axis) {
      const axisConfig = this[`${axis}AxisConfig`]
      const axisTickLabels = []

      const incrementor = this.getIncrementor(axisConfig)
      let n = axisConfig.min

      do {
        const preciseAxisValue = parseFloat(n.toPrecision(axisConfig.precision))

        axisTickLabels.push({
          coord: this[`normalise${axis.toUpperCase()}`](preciseAxisValue),
          labelText: axis === 'y' ? 
            preciseAxisValue.toLocaleString() : preciseAxisValue
        })

        n += incrementor
      } while( n < axisConfig.max + incrementor)

      return axisTickLabels
    },

    getIncrementor (axisConfig) {
      const range = axisConfig.max - axisConfig.min
      const axisMarks = axisConfig.axisMarks === null ? 
        this.getNumOfAxisMarks() :
        axisConfig.axisMarks

      if (range === 0) { return 0 }
      
      return range / (axisMarks - 1)
    },

    getNumOfAxisMarks () {
      let max = 1

      this.lines.forEach(line => {
        max = Math.max(line.datapoints.length, max)
      })

      return max
    },

    getLineColor (line) {
      return line.color ? line.color : {...DEFAULT_LINE_COLOUR}
    },

    getMinMax(type, prop) {
      let array = []

      this.lines.forEach(line => {
        array.push(Math[type](...line.datapoints.map(t => t[prop])))
      }) 

      const value = array.length ? Math[type](...array) : 0
      
      if (type === 'max' && prop === 'y') {
        return this.getSensibleMaxY(value)
      }

      return value
    },

    getSensibleMaxY (maxY) {
      if (!maxY) { return 0 }
      
      const orderOfMag = Math.floor(Math.log10(maxY))
      let divisorToRoundMantissaUpTo = 4

      if (10 <= maxY && maxY <= 24) {
        divisorToRoundMantissaUpTo = 0.4
      } else if (maxY > 24) {
        divisorToRoundMantissaUpTo = 2
      }

      return divisorToRoundMantissaUpTo * 10 ** orderOfMag * Math.ceil(maxY/divisorToRoundMantissaUpTo * 10 ** -orderOfMag)
    },

    normaliseX (value) {
      // subtract the min value incase the axis doesn't start at 0
      if (this.xAxisConfig.max === this.xAxisConfig.min) {
        return 0
      }

      return this.plotWidth * (value - this.xAxisConfig.min) / (this.xAxisConfig.max - this.xAxisConfig.min)
    },

    normaliseY (value) {
      // y origin is at the top so subtract axis value from height
      // subtract the min value incase the axis doesn't start at 0
      if (this.yAxisConfig.max === this.yAxisConfig.min) {
        return this.plotHeight
      }

      return this.plotHeight * (1 - (value - this.yAxisConfig.min) / (this.yAxisConfig.max - this.yAxisConfig.min))
    },

    getDatapointLabels (line) {
      const labels = []

      line.datapoints.forEach((point, index) => {
        labels.push({ 
          x: this.normaliseX(point.x), 
          y: this.normaliseY(point.y),
          code: line.code,
          value: parseInt(point.y).toLocaleString(),
          markerId: `line-${line.id}-marker-${index}`
        })
      })

      return labels
    },
  }
}
</script>