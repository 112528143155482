import axios from 'axios'

export const LOADING_STATES = {
  loaded: 'loaded',
  loadingChart: 'loading-chart',
  loadingFullList: 'loading-full-list',
  downloadingChart: 'downloading-chart',
  downloadingCsv: 'downloading-csv',
  errorChart: 'error-chart',
  errorFullList: 'error-full-list'
}

export const fetchFilters = function(cb) {
  const locale = this.$store.state.translations.locale.id

  axios
    .get(`/api/v1/sapi/filters.json?sapi[locale]=${locale}`)
    .then(cb)
}
const EXPORTERS_CONFIG = {
  call: 'grouped',
  grouping: 'exporting'
}
const IMPORTERS_CONFIG = {
  call: 'grouped',
  grouping: 'importing'
}
const TERMS_CONFIG = {
  call: 'grouped',
  grouping: 'terms'
}
const TAXONOMIC_CONFIG = {
  call: 'grouped',
  grouping: 'taxonomy'
}
const SOURCE_OVER_TIME_CONFIG = {
  call: 'over_time',
  grouping: 'sources',
}
const REQUEST_CONFIG = {
  exporters: EXPORTERS_CONFIG,
  importers: IMPORTERS_CONFIG,
  terms: TERMS_CONFIG,
  taxonomic: TAXONOMIC_CONFIG,
  source_over_time: SOURCE_OVER_TIME_CONFIG,
}

const isCountryPageChart = id => isReportedByImporter(id) || isReportedByExporter(id)
const isReportedByImporter = id => id.match(/_imp$/)
const isReportedByExporter = id => id.match(/_exp$/)
const getBaseId = id => id.replace(/(_exp$)|(_imp$)/g, '')

const getCompParams = compId => {
  const params = REQUEST_CONFIG[getBaseId(compId)]
  let reported_by = 'exporter'
  let call = params.call

  if (isReportedByImporter(compId)) {
    reported_by = 'importer'
  }

  if (call !== 'over_time' && isCountryPageChart(compId)) {
    call = 'country'
  }

  return {...params, reported_by, call}
}

const getComponentQueryString = (locale, compId, params) => {
  const queryParams = getQueryParams(compId, params)
  let queryString = `sapi[locale]=${locale}`

  Object.keys(queryParams).forEach((key) => {
    queryString += `&sapi[${key}]=`
    const stringKey = queryParams[key].toString()

    if (stringKey && stringKey.toLowerCase() !== 'null') {
      queryString += stringKey
    }
  })

  return queryString
}

const getQueryParams = (compId, params) => ({...getCompParams(compId), ...params})

const SAPI_API_PATH = '/api/v1/sapi'

export const fetchComponentData = (locale, compId, params, successCb, errorCb=defaultErrorCallback) => {
  const url = SAPI_API_PATH + '?' + getComponentQueryString(locale, compId, params)

  axios
    .get(url)
    .then(successCb)
    .catch(errorCb)
}

const defaultErrorCallback = e => console.log(e)

export const fetchComponentCSV = (locale, compId, params, successCallback, failureCallback) => {
  const url = SAPI_API_PATH + '/download.json?' + getComponentQueryString(locale, compId, params)

  axios
    .get(url, {
      responseType: 'arraybuffer',
      headers: {
        'Accept': 'image/png'
      }
    })
    .then(successCallback)
    .catch(failureCallback)
}

export const getAutocompleteEndpoint = searchTerm =>
  `/api/v1/sapi/species_autocomplete?sapi[query]=${searchTerm}`

export const fetchChartDownload = ({locale, chartDownloadParams, csvQueryParams, successCallback, failureCallback}) => {
  axios
    .post('/download-zip?' + getComponentQueryString(locale, chartDownloadParams.id, csvQueryParams),
      {
        ...chartDownloadParams
      },
      {
        responseType: 'arraybuffer',
        headers: {
          'Accept': 'application/zip'
        }
      }
    )
    .then(successCallback)
    .catch(failureCallback)
}

export const downloadChart = (res, translation) => {
  const blob = new Blob([res.data], {type: 'application/zip'})
  const currentDateStr = getCurrentDate()
  const fileName = `CITESWildlifeTradeView_${translation}_${currentDateStr}.zip`

  downloadFile(fileName, blob)
}

export const downloadCSV = res => {
  const blob = new Blob([res.data], {type: 'text/csv'})
  const currentDateStr = getCurrentDate()
  const fileName = `CITESWildlifeTradeView_CSV_${currentDateStr}.csv`

  downloadFile(fileName, blob)
}

const getCurrentDate = () => new Date().toLocaleDateString('en-US').replace(/\//g, '')

const downloadFile = (fileName, blob) => {
  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, fileName)
  } else {
    const link = document.createElement('a')

    link.href = window.URL.createObjectURL(blob)
    link.download = fileName
    link.click()
  }
}

const setCsrfToken = axios => {
  const csrf = document.querySelectorAll('meta[name="csrf-token"]')[0].getAttribute('content')

  axios.defaults.headers.common['X-CSRF-Token'] = csrf
}

setCsrfToken(axios)
