<template>
  <div class="component-legend">
    <h2 class="component-legend__title no-margin--top print-only">
      <strong>{{ translations.legend }}</strong> ({{ units }})
    </h2>
    <ul :class="`component-legend__items flex flex-no-shrink`">
      <li
        v-for="(item, index) in items"
        :key="index"
        class="component-legend__item flex flex-v-center relative"
        :class="getItemClickableClass(index)"
        tabindex="0"
        @mouseover="onItemMouseover(item, index)"
        @mouseleave="onItemMouseleave(item, index)"
        @focus="onItemMouseover(item, index)"
        @blur="onItemMouseleave(item, index)"
        @click="click(item, index)"
        @keypress.enter="click(item, index)"
        @keypress.space="click(item, index)"
      >
        <div
          class="focus-blocker"
          tabindex="-1"
        />
        <span
          class="component-legend__key flex-no-shrink"
          :style="getKeyColorStyling(item, index)"
        />
        <span class="flex">
          <span
            class="component-legend__index"
            :style="[itemUnreported(item) ? invisibleStyles : {}]"
          >
            {{ getIndexString(item, index) }}
          </span>
          <span v-html="getLabel(item)" />
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    items: { //{id: String, name: String, value: Number}
      type: Array,
      default: () => []
    },
    hasClick: {
      type: Boolean,
      default: false
    },
    selectedIndex: {
      type: Number,
      default: -1
    },
    colorPalette: {
      type: Array,
      default: () => []
    },
    units: {
      type: String,
      default: ''
    },
    indexKey: {
      type: String,
      default: ''
    }
  },

  data: function () {
    return {
      invisibleStyles: {
        visibility: 'hidden'
      }
    }
  },

  computed: {
    translations () {
      return this.$store.state.translations.translations.misc
    }
  },

  methods: {
    click (item, index) {
      if(this.hasClick) {
        this.$emit('item-click', {item, index})
      }
    },

    getIndexString (item, index) {
      if (this.indexKey) {
        return `${item[this.indexKey]}. `
      }

      return `${index+1}. `
    },

    getItemClickableClass (index) {
      return {
        'component-legend__item--clickable': this.hasClick,
        'selected': this.selectedIndex === index
      }
    },

    onItemMouseover (item, index) {
      this.$emit('item-mouseover', {item, index})
    },

    onItemMouseleave (item, index) {
      this.$emit('item-mouseleave', {item, index})
    },

    getLabel (item) {
      const valueString = !isNaN(item.value) ?
        `(${parseInt(item.value).toLocaleString()})` : ''

      return `<strong class="component-legend__item-name">${item.name}</strong> <br class="print-only"> ${valueString}`
    },

    getKeyColorStyling (item, index) {
      const style = {}

      if (this.colorPalette.length) {
        style.backgroundColor = this.colorPalette[index]
      } else {
        style.backgroundColor = item.color ? item.color.line : '#000000'
      }

      return style
    },

    itemUnreported (item) {
      return /unreported/i.test(item.id)
    }
  },
}
</script>
