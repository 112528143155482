<template>
  <div
    class="modal"
    :class="{ 'active' : isActive }"
    @click.self="closeModal"
  >
    <div 
      class="modal__content"
      role="dialog"
      aria-modal="true"
    >
      <button
        class="modal__close"
        @click="closeModal"
      />

      <slot />
    </div>
  </div>
</template>

<script>
import mixinFocusCapture from '../../mixins/mixin-focus-capture'
import mixinPopupCloseListeners from '../../mixins/mixin-popup-close-listeners'
import { setBodyScroll } from '../../helpers/dom-helper'

export default {
  name: 'Modal',

  mixins: [
    mixinFocusCapture({toggleVariable: 'isActive', closeCallback: 'closeModal'}), 
    mixinPopupCloseListeners({closeCallback: 'closeModal', closeOnClickOutside: false})
  ],

  props: {
    isActive: {
      type: Boolean,
      default: false
    }
  },

  watch: {
    isActive () {
      setBodyScroll(!this.isActive)
    }
  },

  methods: {
    closeModal () {
      this.$emit('close-modal')
    }
  }
}
</script>