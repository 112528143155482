import * as d3 from 'd3'
import 'innersvg-polyfill'

import ChartDatapointLabel from './ChartDatapointLabel'
import { removeClass, addClass } from '../../../helpers/dom-helper'

export default class CircularPackingChart {
  constructor(selector, data, options) {
    this.container = d3.select(selector)
    this.colorPalette = options.colorPalette
    this.data = data
    this.maxValue = 0
    this.max3Sum = 0
  }

  setMaxRadii() {
    const unscaledRadii = this.data
      .map(d => Math.sqrt(d.value))
      .sort((a, b) => b - a)

    this.maxUnscaledRadius = unscaledRadii[0]
    this.top3UnscaledRadiiSum = unscaledRadii
      .slice(0,3)
      .reduce((a, b) => a + b, 0)
  }

  remove () {
    this.container.select('svg').remove()
  }

  resize () {
    this.remove()
    this.init()
  }

  init () {
    this.setMaxRadii()
    const {height, width} = this.container.node().getBoundingClientRect()

    const maxUnscaledCombinedBubbleLength = 2 * Math.max(this.top3UnscaledRadiiSum, 2 * this.maxUnscaledRadius)
    //approx size to ensure bubbles aren't larger than svg
    const maxRadius = (Math.min(height, width) - 30) * this.maxUnscaledRadius / maxUnscaledCombinedBubbleLength 
 
    const svg = this.container
      .append('svg')
      .attr('width', width)
      .attr('height', height)
  
    const radius = area => 
      Math.sqrt(area) * maxRadius / this.maxUnscaledRadius

    // Initialize the circle: all located at the center of the svg area
    const node = svg.append('g')
      .selectAll('circle')
      .data(this.data)
      .enter()
      .append('circle')
      .attr('class', 'node')
      .attr('r', function(d){ return radius(d.value)})
      .attr('cx', width / 2)
      .attr('cy', height / 2)
      .style('fill', (d,i) => this.colorPalette[i])
      .on('mouseenter', function(d) {
        addClass(document.getElementById(d.id), 'active')
      })
      .on('mouseleave', function(d) {
        removeClass(document.getElementById(d.id), 'active')
      })

    const label = svg.append('g')
      .selectAll('g')
      .data(this.data)
      .enter()
      .append('g')
      .attr('id', function(d) { return d.id })
      .attr('class', 'chart__label')
      .html((d,i) => {
        return new ChartDatapointLabel({
          x: d.x, 
          y: d.y, 
          text: `${parseInt(d.value).toLocaleString()}`, 
          index: i+1,
          color: {
            line: this.colorPalette[i],
            fill: '#fff',
            text: '#000'
          }
        }).createLabel()
      })


    // Features of the forces applied to the nodes:
    const simulation = d3.forceSimulation()
      .force('center', d3.forceCenter().x(width / 2).y(height / 2)) // Attraction to the center of the svg area
      .force('charge', d3.forceManyBody().strength(.1)) // Nodes are attracted one each other of value is > 0
      .force('collide', d3.forceCollide().strength(.2).radius(function(d){ return (radius(d.value)+3) }).iterations(1)) // Force that avoids circle overlapping
  
    // Apply these forces to the nodes and update their positions.
    // Once the force algorithm is happy with positions ('alpha' value is low enough), simulations will stop.
    simulation
      .nodes(this.data)
      .on('tick', function(){
        node
          .attr('cx', function(d){ return d.x })
          .attr('cy', function(d){ return d.y })

        label
          .attr('transform', function (d) { 
            return `translate(${d.x},${d.y - radius(d.value)}) `
          })
      })
  }
}