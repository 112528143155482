import { LOADING_STATES } from '../helpers/request-helper'

export default (dataName = 'data') => ({
  data () {
    return {
      showOverlay: true,
      overlayTimeout: null
    }
  },

  computed: {
    loadingState () {
      return this.$store.state.loadingStateById[this.componentId] || ''
    },

    showOverlayComputed () {
      return !this[dataName] || 
        !this[dataName].length || 
        this.isChartLoading
    },

    isChartLoading () {
      return this.loadingState === LOADING_STATES.loadingChart
    },

    hasChartError () {
      return this.loadingState === LOADING_STATES.errorChart
    },

    isFullListLoading () {
      return this.loadingState === LOADING_STATES.loadingFullList
    },

    hasFullListError () {
      return this.loadingState === LOADING_STATES.errorFullList
    }
  },

  watch: {
    showOverlayComputed () {
      this.setShowOverlay()
    }
  },

  mounted () {
    this.setShowOverlay()
  },

  methods: {
    setShowOverlay () {
      if (this.showOverlayComputed === true) {
        this.overlayTimeout = setTimeout(() => {
          this.showOverlay = true
        }, 250)
      } else {
        clearTimeout(this.overlayTimeout)

        this.showOverlay = false
      }
    }
  }
})