<template>
  <div class="accordion">
    <slot />
  </div>
</template>

<script>

export default {
  name: 'Accordion',

  data () {
    return {
      children: this.$children
    }
  },

  mounted () {
    this.$eventHub.$on('call-toggle-accordion-content', this.toggleAccordionContent)
  },

  methods: {
    toggleAccordionContent (id) {
      this.children.forEach(child => {
        child.isActive = child.id === id && child.isActive !== true
      })
    }
  }
}
</script>
