<template>
  <div 
    :id="componentId"
    :class="isHalfWidth ? 'component--half-width': 'component'"
  >
    <component-header
      :title="title"
      :component-id="componentId"
      :tabs="tabs"
      initial-tab-id="taxon"
      :is-half-width="isHalfWidth"
      :disabled="showOverlay"
      @update:selected-tab="updateSelectedTabId"
    />
    <div class="component__chart-legend-wrapper">
      <div class="component__chart">
        <div 
          :id="svgId" 
          class="circular-packing-chart" 
        />
        <div 
          class="component__disclaimer" 
          v-html="chartSmallprint" 
        />
      </div>

      <component-legend 
        :class="italiciseNames"
        :items="dataWithUniqueIds" 
        :color-palette="colorPalette"
        :units="units"
        @item-mouseover="onLabelMouseover"
        @item-mouseleave="onLabelMouseleave"
      />

      <component-loading-overlay
        v-if="showOverlay"
        :loading="isChartLoading"
        :error="hasChartError"
      />
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import CircularPackingChart from '../charts/helpers/circular-packing'
import ComponentHeader from '../component/ComponentHeader'
import ComponentLegend from '../component/ComponentLegend'
import ComponentLoadingOverlay from '../component/ComponentLoadingOverlay'

import mixinComponentLoadingOverlay from '../../mixins/mixin-component-loading-overlay'
import { addClass, removeClass } from '../../helpers/dom-helper'

export default {
  name: 'ChartCircularPackingComponent',

  components: { ComponentHeader, ComponentLegend, ComponentLoadingOverlay },
  
  mixins: [mixinComponentLoadingOverlay()],

  props: {
    componentId: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: ''
    },
    data: { // {id: String, name: String, value: Number}[]
      type: Array,
      required: true
    },
    colorPalette: {
      type: Array,
      default: () => ['#FEB958', '#4BBF76', '#EA6E8F', '#5DB9D1', '#7D8DE2']
    },
    tabs: {
      type: Array,
      default: () => []
    },
    isHalfWidth: {
      type: Boolean,
      default: false
    },
    units: {
      type: String,
      default: ''
    },
    chartSmallprint: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      getChart: () => null,
      selectedTabId: '',
      svgId: `${this.componentId}-circular-packing-chart`
    }
  },

  computed: {
    italiciseNames () {
      return {
        'component-legend--italicise-names': ['taxon', 'genus'].indexOf(this.selectedTabId) >= 0
      }
    },

    dataWithUniqueIds () {
      return this.data.map(d => ({ ...d, id: `${d.id}_${this._uid}` }))
    }
  },

  watch: {
    dataWithUniqueIds () {
      this.$nextTick(this.initChart)
    }
  },

  mounted() {
    this.$nextTick(this.initChart)
  },

  methods: {
    onLabelMouseover (e) {
      addClass(this.getNthChartLabel(e.index), 'active')
    },

    onLabelMouseleave (e) {
      removeClass(this.getNthChartLabel(e.index), 'active')
    },

    getNthChartLabel (n) {
      return this.$el.querySelectorAll('.chart__label')[n]
    },

    initChart () {
      if (this.getChart()) { this.getChart().remove() }

      const chart = new CircularPackingChart(
        '#' + this.svgId,
        this.dataWithUniqueIds,
        {
          colorPalette: this.colorPalette
        }
      )

      chart.init()

      window.addEventListener('resize', () => { chart.resize() })
      this.getChart = () => chart
    },

    updateSelectedTabId (tabId) {
      this.selectedTabId = tabId
      this.$emit('update:selected-tab', tabId)
    }
  }
}
</script>
