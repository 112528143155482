<template>
  <div
    v-if="metadata.total"
    class="pagination flex flex-h-end flex-v-center"
  >
    <span class="pagination__label" v-html="showingMessage" />

    <button
      :class="{'disabled': firstPage || disabled}"
      class="pagination__icon--left-end"
      @click="setPage(1)"
    />

    <button
      :class="{'disabled': firstPage || disabled}"
      class="pagination__icon--left"
      @click="changePage(-1)"
    />

    <button
      v-for="n in getNumberedButtonsArray()"
      :key="n"
      class="pagination__icon--page-number"
      :class="{'disabled': isCurrentPage(n) || disabled}"
      @click="setPage(n)"
    >
      {{ n }}
    </button>

    <button
      :class="{'disabled': lastPage || disabled}"
      class="pagination__icon--right"
      @click="changePage(1)"
    />

    <button
      :class="{'disabled': lastPage || disabled}"
      class="pagination__icon--right-end"
      @click="setPage(totalPages)"
    />
  </div>
</template>

<script>
export default {
  props: {
    metadata: {
      type: Object,
      default: () => {}
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      numberedPageButtonCount: 6
    }
  },

  computed: {
    showingMessage () {
      return `${this.translations.showing_rows} <strong>${this.rowCount}</strong> ${this.translations.of} ${this.metadata.total}`
    },

    rowCount() {
      let max = Number(this.metadata.page) * Number(this.metadata.per_page)
      let min = max - Number(this.metadata.per_page) + 1

      if (this.lastPage) {
        max = this.metadata.total
      }

      return `${min} ${this.translations.to} ${max}`
    },

    firstPage() {
      return Number(this.metadata.page) === 1
    },

    lastPage() {
      return Number(this.metadata.page) === this.totalPages
    },

    totalPages() {
      return Math.ceil(Number(this.metadata.total) / Number(this.metadata.per_page))
    },

    translations () {
      return this.$store.state.translations.translations.misc
    }
  },
  methods: {
    isCurrentPage (index) {
      return this.metadata.page === index
    },

    setPage(page) {
      this.$emit('change-page', Number(page))
    },

    changePage(pageOffset) {
      this.$emit('change-page', Number(this.metadata.page) + pageOffset)
    },

    getNumberedButtonsArray () {
      const numberOfPages = Math.ceil(this.metadata.total / this.metadata.per_page)
      const minPageWhenOnFinal = Math.max(1, numberOfPages - this.numberedPageButtonCount + 1)
      const minPage = Math.min(this.metadata.page, minPageWhenOnFinal)
      const maxPage = Math.min(numberOfPages, this.metadata.page + this.numberedPageButtonCount - 1)

      return this.getArrayFromTo(minPage, maxPage)
    },

    getArrayFromTo (first, last) {
      const array = []

      for (let i = first; i <= last; i++) {
        array.push(i)
      }

      return array
    }
  }
}
</script>
