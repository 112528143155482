export const marker = function(layer, iso3, options) {
  const self = this
  const svg = this.svg

  const classNameCircle = 'marker-circle'
  const classNameLabel = 'marker-label'

  const markers = layer
    .selectAll(`.${classNameCircle}`)
    .data(iso3, JSON.stringify)

  svg.selectAll(`.${classNameCircle}`).remove()
  svg.selectAll(`.${classNameLabel}`).remove()

  if (!iso3) {
    return
  }

  const centroid = self.path.centroid(svg.select(`path.${iso3}`).data()[0])

  markers
    .enter()
    .append('circle')
    .attr('class', classNameCircle)
    .attr('cx', centroid[0])
    .attr('cy', centroid[1])
    .attr('r', 15)
    .style('stroke', options.color)
    .style('fill', '#ffffff')

  markers
    .enter()
    .append('text')
    .attr('class', classNameLabel)
    .attr('x', centroid[0])
    .attr('y', centroid[1] + 2)
    .attr('text-anchor', 'middle')
    .attr('dy', '0.25em')
    .text(options.value)
}