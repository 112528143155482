<template>
  <div
    class="v-select v-select--search relative"
    :class="{'disabled': isDisabled, 'active': isActive}"
  >
    <input
      :id="config.id"
      v-model="selectedInternal"
      type="hidden"
      :name="config.id"
    >

    <div
      v-if="config.label"
      class="v-select__label"
    >
      <label
        :for="toggleId"
        class="v-select__selection"
      >{{ config.label }}</label>
      <slot name="label-icon" />
    </div>

    <label 
      :for="selectedOptionsId"
      class="screen-reader"
    >
      {{ translations.selected_options }}
    </label>
    <ul
      v-show="selectedInternal.length"
      :id="selectedOptionsId" 
      class="v-select__selected-options flex flex-wrap"
      role="listbox" 
    >
      <li
        v-for="(option, index) in selectedInternal"
        :key="getVForKey('selected-option', index)"
        role="option"
      >
        <button 
          class="v-select__selected-option hover--pointer"
          @click="deselectOption(option)"
        >
          {{ option.name }}
        </button>
      </li>
    </ul>

    <div class="v-select__click-area">
      <div class="v-select__search relative">
        <label
          class="screen-reader"
          :for="searchId"
        >{{ getSearchLabel(config) }}</label>
        <input
          :id="searchId"
          v-model="searchTerm"
          class="v-select__search-input"
          type="text"
          role="combobox"
          aria-haspopup="listbox"
          aria-autocomplete="list"
          :aria-expanded="showOptions.toString()"
          :aria-owns="dropdownId"
          :aria-activedescendant="highlightedOptionId" 
          :placeholder="placeholder"
          :disabled="isDisabled"
          @focus="openSelect"
        >

        <span class="v-select__search-icons">
          <span
            v-show="showSearchIcon"
            class="v-select__search-icon"
          />
          <button 
            v-show="showResetIcon"
            :id="searchResetId"
            class="v-select__search-icon--reset hover--pointer"
            @click="resetSearchTermClick"
          />
          <span 
            class="drop-arrow v-select__drop-arrow arrow-svg hover--pointer"
            @click="toggleSelect"
          />
        </span>
      </div>

      <ul 
        v-show="showOptions" 
        :id="dropdownId" 
        role="listbox" 
        class="v-select__dropdown"
      >
        <li
          v-for="(option, index) in filteredOptions"
          v-show="matchesSearchTerm(option)"
          :id="getOptionInputId(option)"
          :key="option.id"
          :class="['v-select__option hover--pointer', conditionalOptionClasses(option, index)]"
          role="option"
          :aria-selected="isHighlighted(index).toString()"
          @click.stop="selectOption(option)"
        >
          {{ option.name }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import mixinIds from '../../mixins/mixin-ids'
import mixinSelectShared from '../../mixins/mixin-select-shared'

export default {
  mixins: [
    mixinIds,
    mixinSelectShared
  ],

  props: {
    config: {
      required: true,
      type: Object
    },
    options: {
      default: () => [],
      type: Array
    },
    selected: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      selectedInternal: [],
    }
  },

  computed: {
    filteredOptions () {
      return this.options.filter(option =>
        this.matchesSearchTerm(option) && !this.isSelected(option))
    },

    selectedOptionsId () {
      return `${this.getComponentId()}-selected-options`
    }
  },

  watch: {
    selected (newSelectedOption) {
      this.selectedInternal = newSelectedOption === null ?
        [] :
        newSelectedOption
    }
  },

  methods: {
    closeSelectAndFocusSearch () {
      this.closeSelect()
      this.focusSearch()
    },

    closeSelect () {
      this.resetSearchTerm()
      this.resetHighlightedIndex()
      this.isActive = false
    },

    focusSearch () {
      this.$el.querySelector('#' + this.searchId).focus()
    },

    openSelect () {
      if (this.filteredOptions.length > 0) {
        this.searchTerm = ''
        this.isActive = true
      }
    },

    initializeSelectedInternal () {
      if (this.selected === null) {
        this.selectedInternal = []
      } else {
        this.selectedInternal = this.selected
      }
    },

    isSelected (option) {
      let isSelected = false
      
      this.selectedInternal.forEach(selectedOption => {
        if (selectedOption.id === option.id) {
          isSelected = true
        }
      })

      return isSelected
    },

    selectOption (option) {
      this.selectedInternal.push(option)

      const unselectedOptionsLength = this.filteredOptions.length

      if (unselectedOptionsLength === 0) {
        this.resetHighlightedIndex()
        this.closeSelectAndFocusSearch()
      } else if (this.highlightedOptionIndex >  unselectedOptionsLength - 1) {
        this.highlightedOptionIndex--
      }

      this.emitSelection()
    },

    resetSearchTerm () {
      this.searchTerm = ''
    },


    deselectOption (deselectedOption) {
      this.selectedInternal = this.selectedInternal.filter(option => option.id !== deselectedOption.id )
      this.emitSelection()
    }
  }
}
</script>