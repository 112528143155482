<template>
  <div class="v-select v-select--search relative">
    <div
      v-if="config.label"
      class="v-select__label"
    >
      <label
        :for="toggleId"
        class="v-select__selection"
      >{{ config.label }}</label>
      <slot name="label-icon" />
    </div>

    <label 
      :for="selectedOptionsId"
      class="screen-reader"
    >
      {{ translations.selected_options }}
    </label>

    <ul
      v-show="selected.length"
      :id="selectedOptionsId" 
      class="v-select__selected-options flex flex-wrap"
      role="listbox" 
    >
      <li
        v-for="(option, index) in selected"
        :key="getVForKey('selected-option', index)"
        role="option"
      >
        <button 
          class="v-select__selected-option hover--pointer"
          @click="deselectOption(option)"
        >
          {{ option.name }}
        </button>
      </li>
    </ul>

    <div class="v-select__click-area">
      <div class="v-select__search relative">
        <label
          class="screen-reader"
          :for="searchId"
        >{{ getSearchLabel(config) }}</label>

        <autocomplete 
          :id="searchId"
          :placeholder="config.label"
          :autocomplete-callback="autocompleteCallback"
          :error-messages="errorMessages"
          @submit="pushSelection"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Autocomplete from '../autocomplete/Autocomplete'
import mixinIds from '../../mixins/mixin-ids'

export default {
  components: {
    Autocomplete
  },

  mixins: [mixinIds],
  
  props: {
    config: {
      type: Object,
      required: true
    },
    autocompleteCallback: {
      type: Function,
      required: true
    }
  },

  data () {
    return {
      selected: [],
      toggleId: 'v-select-toggle-' + this.config.id,
      searchId: 'v-select-search-' + this.config.id,
    }
  },

  computed: {
    translations () {
      return this.$store.state.translations.translations.filters
    },

    errorMessages () {
      return this.translations.autocomplete_errors
    },

    selectedOptionsId () {
      return `${this.getComponentId()}-selected-options`
    },
  },

  created () {
    this.$eventHub.$on('filters:reset', this.resetSelections)
  },

  methods: {
    pushSelection (selection) {
      this.selected.push(selection)
      this.emitSelection()
    },

    deselectOption (deselectedOption) {
      this.selected = this.selected.filter(option => option.id !== deselectedOption.id )
      this.emitSelection()
    },

    resetSelections () {
      this.selected = []
    },

    emitSelection () {
      this.$emit('update:selected-option', this.selected)
    },

    getSearchLabel (filterConfig) {
      return `${filterConfig.label} ${this.translations.search}`
    },
  }
}
</script>