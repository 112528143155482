<template>
  <div 
    v-if="isActive" 
    class="screen-overlay"
  >
    <div 
      v-if="showPrompt"
      class="screen-overlay__prompt"
    >
      {{ overlayMessage }}
      <div 
        v-if="loading"
        class="loader screen-overlay__loader" 
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showPrompt: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      isActive: true,
      overlayMessage: '',
      loading: false
    }
  },

  created () {
    this.$eventHub.$on('open-overlay', this.openOverlay)
    this.$eventHub.$on('close-overlay', this.closeOverlay)
  },

  methods: {
    openOverlay (payload) {
      if (payload.overlayId === this.id) {
        this.isActive = true
        this.overlayMessage = payload.overlayMessage
        this.loading = payload.loading

        if (this.id === 'dashboard-overlay') {
          this.$store.commit('updateIsDashboardOverlayShown', true)
        }

        if (payload.scrollIdentifier) {
          this.disableScrollOnBackgroundElement(payload.scrollIdentifier)
        }
      }
    },

    closeOverlay (payload) {
      if (payload.overlayId === this.id) {
        this.isActive = false
        this.overlayMessage = ''
        this.loading = false

        if (this.id === 'dashboard-overlay') {
          this.$store.commit('updateIsDashboardOverlayShown', false)
        }

        if (payload.scrollIdentifier) {
          this.enableScrollOnBackgroundElement(payload.scrollIdentifier)
        }
      }
    },

    disableScrollOnBackgroundElement (scrollIdentifier) {
      const scrollContainer = document.querySelector(scrollIdentifier)

      scrollContainer.style.overflowY = 'hidden'
      scrollContainer.scrollTop = 0
    },

    enableScrollOnBackgroundElement (scrollIdentifier) {
      document.querySelector(scrollIdentifier).style.overflowY = 'scroll'
    }
  }
}
</script>