<template>
  <g transform="translate(0,-30)">
    <rect 
      :fill="colourFill"
      :stroke="colourLine"
      :stroke-width="strokeWidth"
      :width="labelWidth"
      :height="labelHeight"
      :x="-labelWidth/2"
      :y="-labelHeight/2"
      :rx="borderRadius"
    />
    <text 
      :fill="colourText" 
      text-anchor="middle" 
      :style="`font-size: ${fontSize}px; font-weight: ${fontWeight};`"
      y="0.35em"
    >
      <tspan style="font-weight: 300;">
        {{ indexText }}
      </tspan>
      {{ text }}
    </text>
    <rect
      :fill="colourFill"
      :width="triangleWidth"
      :height="strokeWidth + 2"
      :x="-triangleWidth/2"
      :y="labelHeight/2-strokeWidth/2-1"
    />
    <polyline 
      :points="`-${triangleWidth/2},${labelHeight/2} 0,${labelHeight/2 + triangleHeight} ${triangleWidth/2},${labelHeight/2}`" 
      :fill="colourFill" 
      :stroke="colourLine" 
      :stroke-width="strokeWidth"
    />
  </g>
</template>

<script>
import { setOptions } from './helpers/ChartDatapointLabel'

export default {
  props: {
    options: {
      type: Object,
      default: () => {}
    }
  },

  data () {
    return {
      labelWidth: 0,
      labelHeight: 0,
      strokeWidth: 0,
      borderRadius: 0,
      triangleWidth: 0,
      triangleHeight: 0,

      x: 0,
      y: 0,
      text: '',
      indexText: '',
      colourLine: '',
      colourFill: '',
      colourText: '',
      fontSize: 0,
      fontWeight: 0,
    }
  },

  watch: {
    options () {
      this.assignOptions()
    }
  },

  created () {
    this.assignOptions()
  },

  methods: {
    assignOptions () {
      setOptions.bind(this)(this.options)
    }
  },
}
</script>