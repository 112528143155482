export default {
  computed: {
    colorPaletteIndexBySourceId () {
      return this.$store.state.sourcesColorPalette
    },
    
    filterConfigs () {
      return this.$store.state.filterConfigs
    },
    
    filterOptions () {
      return this.$store.state.filterOptions
    },
    
    isDashboardOverlayShown () {
      return this.$store.state.isDashboardOverlayShown
    },
    
    selectedFilters () {
      return this.$store.state.selectedFilters
    },

    selectedGroupFilters () {
      return this.$store.state.selectedGroupFilters
    },

    appliedFilters () {
      return this.$store.state.appliedFilters
    },

    appliedGroupFilters () {
      return this.$store.state.appliedGroupFilters
    },
  },

  methods: {
    getComponentSpecificParams (compId) {
      const params = this.$store.state[compId + '_params']

      return params ? params : {}
    },

    getFilterOptions (filterId) {
      return filterId in this.filterOptions ? 
        this.filterOptions[filterId] : []
    },

    getAppliedSelectionFromStoreSelection (filterId) {
      if (this.filterConfigs[filterId].isMultiselect) {
        return this.getAppliedFilterOptions(filterId)
      }

      return this.getAppliedFilterOption(filterId)  
    },

    getAppliedFilterOptions (filterId, grouped=false) {
      return this.getFilterOptionsFromStore(filterId, true, grouped)
    },

    getSelectedFilterOptions (filterId, grouped=false) {
      return this.getFilterOptionsFromStore(filterId, false, grouped)
    },

    getFilterOptionsFromStore (filterId, applied=false, grouped=false) {
      const filters = applied ? this.appliedFilters : this.selectedFilters
      let optionIds = filters[filterId] || []

      if (grouped) {
        optionIds = this.replaceOptionsWithGroupOptions(filterId, optionIds)
      }

      return optionIds.map(optionId => 
        this.getFilterOptionFromStoreSelection(filterId, optionId)
      )
    },

    replaceOptionsWithGroupOptions (filterId, optionIds, applied=false) {
      const groupFiltersById = applied ? this.appliedGroupFilters : this.selectedGroupFilters
      const groupFilters = groupFiltersById[filterId] || []
      
      groupFilters.forEach(groupOptionId => {
        const groupOption = this.getFilterOptionFromStoreSelection(filterId, groupOptionId)
        
        optionIds = optionIds.filter(id => groupOption.queryValues.indexOf(id) < 0)
        optionIds.push(groupOptionId)
      })

      return optionIds
    },

    getAppliedFilterOption (filterId) {
      return this.getFilterOptionFromStoreSelection(filterId, this.appliedFilters[filterId])
    },

    getSelectedFilterOption (filterId) {
      return this.getFilterOptionFromStoreSelection(filterId, this.selectedFilters[filterId])
    },

    getFilterOptionFromStoreSelection (filterId, storeSelection) {
      return this.getFilterOptions(filterId).filter(filterOption => 
        filterOption.queryValue === storeSelection
      )[0]
    },

    getSelectedTab (comp) {
      const params = this.$store.state[`${comp.id}_params`]

      return params ? params[comp.tab_param_id] : null
    },

    getAppliedSelectionsForDisplay () {
      const selections = {
        date_range: this.getAppliedDateRange()
      }

      Object.keys(this.appliedFilters).forEach(filterId => {
        if (this.filterOptions[filterId] && this.includeInDisplay(filterId)) {
          selections[filterId] = {
            label: this.filterConfigs[filterId].label, 
            selection: this.getSelectionStringForDownload(filterId)
          }
        }
      })

      return selections
    },

    getAppliedDateRange () {
      return {
        label: this.filterConfigs.time_range_start.label,
        selection: `${this.appliedFilters.time_range_start} - ${this.appliedFilters.time_range_end}`
      }
    },

    includeInDisplay (filterId) {
      const selected = this.appliedFilters[filterId]

      if (Array.isArray(selected) && !selected.length) {
        return false
      }

      return !filterId.match('time_range') && 
        filterId !== 'reported_by_party' && 
        selected
    },

    getSelectionStringForDownload(filterId) {
      const selection = this.getAppliedSelectionFromStoreSelection(filterId)

      if (Array.isArray(selection)) {
        return selection.map(o => o.nameForChartDownload).join(', ')
      }

      return selection.nameForChartDownload
    },

    updateCompLoadingState(comp, loadingState) {
      this.$store.dispatch('updateComponentLoadingState', {
        id: comp.id, 
        loadingState
      })
    },
  }
}