<template>
  <div class="tabs">
    <ul
      role="tablist"
      class="tabs__triggers flex flex-nowrap"
    >
      <li 
        v-for="tab in tabs"
        :id="triggerId(tab)"
        :key="triggerId(tab)"
        role="tab"
        tabindex="0"
        :aria-disabled="isDashboardLoading"
        :aria-controls="tabContainerId"
        :aria-selected="isSelected(tab)"
        :class="getTabClasses(tab)" 
        @keypress.enter.prevent="click(tab)" 
        @keypress.space.prevent="click(tab)" 
        @click="click(tab)"
      >
        <label class="tab__title">
          {{ tab.label }}
        </label>
      </li>
    </ul>
    <div
      :id="tabContainerId"
      role="tabpanel"
      :aria-labelledby="triggerId(selectedTab)"
      class="tab__container section-padding"
    >
      <div class="tab">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FakeTabs',

  props: {
    initActiveId: {
      type: String,
      default: ''
    },
    tabs: { //{id: String, label: String, data: any}[]
      type: Array,
      default: () => []
    },
    filterId: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      selectedId: '',
      tabContainerId: 'tab-' + this._uid
    }
  },

  computed: {
    selectedTab () {
      let selectedTab = ''

      this.tabs.forEach(tab => {
        if (this.isSelected(tab)) {
          selectedTab = tab
        }
      })

      return selectedTab
    },

    isDashboardLoading () {
      return this.$store.state.isDashboardLoading
    }
  },

  created () {
    this.initSelected()
  },

  methods: {
    click (tab) {
      this.updateSelectedTabId(tab.id)
    },

    getTabClasses (tab) {
      return [
        'tab__trigger flex-no-shrink hover--pointer', 
        { 
          'active' : this.isSelected(tab),
          'disabled': this.isDashboardLoading
        }
      ]
    },

    initSelected () {
      const selectedId = this.initSelectedId ? this.initSelectedId : this.tabs[0].id
      
      this.updateSelectedTabId(selectedId)
    },

    updateSelectedTabId (tabId) {
      this.selectedId = tabId
      const selection = this.selectedId.replace('tab-', '')

      this.$store.commit('selectFilter', {id: this.filterId, selection})
      this.$store.commit('applyFilter', {id: this.filterId, selection})
    },

    isSelected (tab) {
      return this.selectedId === tab.id
    },

    triggerId (tab) {
      return 'tab-trigger-' + tab.id
    }
  }
}
</script>