<template>
  <div>
    <template
      v-for="(filter, index) in shownFilters"
    >
      <component
        :is="getFilterTagName(filter)"
        v-if="filter.shown"
        :key="`${filter.id}-input`"
        :class="filterClasses(filter, index)"
        :autocomplete-callback="getAutocompleteCallback(filter.id)"
        :config="filterConfigs[filter.id]"
        :has-search-icon="!filter.hideSearchIcon"
        :options="getFilterOptionsForVSelect(filter.id)"
        :selected="getSelectedFiltersForVSelect(filter.id)"
        @update:selected-option="updateStore(filter.id, $event)"
      >
        <template
          v-if="filter.tooltip"
          slot="label-icon"
        >
          <tooltip
            class="tooltip--left flex-hs-end"
            :text="filter.tooltip"
            :on-hover="false"
          >
            <div class="tooltip-icon" />
          </tooltip>
        </template>
      </component>
    </template>

    <div class="filters__buttons">
      <button
        class="filters__clear-button"
        :disabled="isDashboardLoading"
        @click="resetFilters"
      >
        {{ translations_buttons.reset }}
      </button>

      <button
        class="filters__apply-button"
        :disabled="isPendingInitSelection || isDashboardLoading"
        @click="applyFilters"
      >
        {{ translations_buttons.apply }}
      </button>
    </div>

    <screen-overlay id="filters-overlay" />
  </div>
</template>

<script>
import ScreenOverlay from '../screen-overlay/ScreenOverlay'
import Tooltip from '../tooltip/Tooltip'
import VMultiselectAutocomplete from '../v-select/VMultiselectAutocomplete'
import VMultiselectSearchable from '../v-select/VMultiselectSearchable'
import VSelectSearchable from '../v-select/VSelectSearchable'
import mixinStoreExplore from '../../mixins/mixin-store-explore'

import { fetchFilters, getAutocompleteEndpoint } from '../../helpers/request-helper'
import { getFilterOptions, MAX_DATE_RANGE } from '../../helpers/filters-helper'
import axios from 'axios'
import isEqual from 'lodash.isequal'

export default {
  components: {
    ScreenOverlay,
    Tooltip,
    VMultiselectAutocomplete,
    VMultiselectSearchable,
    VSelectSearchable
  },

  mixins: [mixinStoreExplore],

  props: {
    filters: {
      type: Array,
      default: () => []
    },
    page: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      areFiltersLoaded: false,
    }
  },

  computed: {
    shownFilters () {
      return this.filters.filter(f => f.shown)
    },

    showFiltersOverlay() {
      return this.isPendingInitSelection || !this.areFiltersLoaded
    },

    showDashboardOverlay() {
      return this.isPendingApplicationOfInitSelection
        || !this.areFiltersLoaded
        || this.areFiltersDirty
    },

    isPendingApplicationOfInitSelection () {
      return this.isPendingSelection(true)
    },

    isPendingInitSelection () {
      return this.isPendingSelection(false)
    },

    overlayPrompt () {
      if (this.isPendingInitSelection) {
        const requiredFilterLabel = this.shownFilters[0].label.toLowerCase()

        return this.translations.prompt_message.replace('${required_filter_label}', requiredFilterLabel)
      } else if (this.isPendingApplicationOfInitSelection) {
        return this.translations.apply_filters_prompt
      } else if (this.areFiltersDirty) {
        return  this.translations.unsaved_warning
      }

      return ''
    },

    translations () {
      return this.$store.state.translations.translations.filters
    },

    translations_buttons () {
      return this.$store.state.translations.translations.buttons
    },

    areFiltersDirty () {
      // requires multiselect orders to match (unnecessary but easier)
      return !isEqual(this.appliedFilters, this.selectedFilters)
    },

    isDashboardLoading () {
      return this.$store.state.isDashboardLoading
    }
  },

  watch: {
    showFiltersOverlay (show) {
      this.setScreenOverlay(show, 'filters-overlay')
    },

    showDashboardOverlay (show) {
      this.setScreenOverlay(show, 'dashboard-overlay')
    },

    areFiltersLoaded () {
      this.setScreenOverlay(
        this.showDashboardOverlay,
        'dashboard-overlay'
      )
    },

    overlayPrompt () {
      // updates overlay text
      this.setScreenOverlay(
        this.showDashboardOverlay,
        'dashboard-overlay'
      )
    },

    selectedFilters () {
      this.handleFilterUpdate()
    }
  },

  created () {
    this.$store.commit('updatePage', this.page)
    this.initFilterConfig()
    this.initFilterOptions()
  },

  mounted () {
    this.setScreenOverlay(true, 'filters-overlay')
    this.setScreenOverlay(true, 'dashboard-overlay')
  },

  methods: {
    isPendingSelection (applied) {
      const filters = applied ? this.appliedFilters : this.selectedFilters

      if (this.page === 'taxon') {
        return filters.taxon_id ? !filters.taxon_id.length : true
      } else if (this.page === 'country') {
        return filters.country_ids ? !filters.country_ids.length : true
      }

      return false
    },

    filterClasses (filter, index) {
      return [
        'filters__filter',
        {'filters__filter--bring-forward': this.bringForward(index)}
      ]
    },

    bringForward (index) {
      return index === 0  && this.isPendingInitSelection
    },

    initFilterConfig () {
      this.$store.dispatch('updateFilterConfigs', this.filters)
    },

    initFilterOptions () {
      fetchFilters.call(this, this.setFilterOptions)
    },

    setFilterOptions (res) {
      this.$store.dispatch('initFilterOptions', res.data)
      this.$store.commit('setSourcesColorPalette', res.data.sources)
      this.areFiltersLoaded = true

      if (!this.isPendingApplicationOfInitSelection) {
        this.applyFilters()
      }
    },

    getSelectedFiltersForVSelect (filterId) {
      if (this.filterConfigs[filterId].isMultiselect) {
        return this.getSelectedFilterOptions(filterId, true)
      }

      return this.getSelectedFilterOption(filterId)
    },

    getFilterOptionsForVSelect (filterId) {
      const selectedGroupFilters = this.selectedGroupFilters[filterId]

      if (selectedGroupFilters && selectedGroupFilters.length) {
        return this.filterOptionsWithoutSelected(filterId)
      }

      return this.getFilterOptions(filterId)
    },

    filterOptionsWithoutSelected (filterId) {
      return this.getFilterOptions(filterId).filter(option =>
        this.selectedFilters[filterId].indexOf(option.queryValue) < 0
      )
    },

    handleFilterUpdate() {
      const startYear = this.selectedFilters.time_range_start

      if (startYear) {
        this.disableTimeRangeEndOptions(startYear)
      }
    },

    disableTimeRangeEndOptions (startYear) {
      const timeRangeEndOptions = this.filterOptions.time_range_end

      timeRangeEndOptions.forEach(endYearOption => {
        const endYear = endYearOption.id

        endYearOption.disabled = this.isValidEndYear(startYear, endYear)
      })
    },

    setScreenOverlay (showOverlay, overlayId) {
      const scrollIdentifier = overlayId === 'filters-overlay' ?
        '.filters' : null

      if (showOverlay) {
        const loading = !this.areFiltersLoaded
        const overlayMessage = loading ? this.translations.filters_loading : this.overlayPrompt

        this.$eventHub.$emit('open-overlay', {
          overlayMessage,
          loading,
          overlayId,
          scrollIdentifier
        })
      } else {
        this.$eventHub.$emit('close-overlay', { overlayId, scrollIdentifier })
      }
    },

    getFilterTagName (filter) {
      if (filter.isAutocomplete) {
        return 'v-multiselect-autocomplete'
      }

      return filter.isMultiselect ? 'v-multiselect-searchable' : 'v-select-searchable'
    },

    updateStore (filterId, options) {
      this.$store.dispatch('updateStoreSelections', { filterId, options })

      if (filterId === 'time_range_start') {
        this.handleEndYearUpdate(options)
      }
    },

    applyFilters () {
      this.$store.commit('applyFilters')
    },

    resetFilters () {
      this.$store.dispatch('resetFilters')
      this.$eventHub.$emit('filters:reset')
    },

    handleEndYearUpdate (timeRangeStartOption) {
      const startYear = timeRangeStartOption.id
      const endYear = this.selectedFilters.time_range_end

      if (this.isValidEndYear(startYear, endYear)) {
        this.$store.commit('selectFilter', {
          id: 'time_range_end',
          selection: startYear + 1
        })
      }
    },

    isValidEndYear (startYear, endYear) {
      return endYear < startYear || Math.abs(endYear - startYear ) > MAX_DATE_RANGE - 1
    },

    getAutocompleteCallback (filterId) {
      return searchTerm => {
        return this.getAutocompletePromise(filterId, searchTerm)
      }
    },

    getAutocompletePromise (filterId, searchTerm) {
      return new Promise((resolve, reject) => {
        axios.get(getAutocompleteEndpoint(searchTerm))
          .then(response => {
            const options = this.getAutocompleteOptions(
              filterId,
              response.data.auto_complete_taxon_concepts
            )

            resolve(Promise.resolve(options))
          })
          .catch(e => reject(Promise.reject(e)))
      })
    },

    getAutocompleteOptions (filterId, results) {
      const options = results.map(
        item => ({ name: item.full_name, id: item.id })
      )
      const filterConfig = this.filterConfigs[filterId]

      return getFilterOptions(
        filterConfig,
        { [filterConfig.optionsId]: options },
        true
      )
    }
  },
}
</script>
