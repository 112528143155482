<template>
  <modal
    :is-active="isActive"
    @close-modal="closeModal"
  >
    <div class="full-list gutters">
      <div class="full-list__title-bar flex flex-v-center flex-h-between">
        <h2 class="full-list__title">
          {{ componentTitle }}
        </h2>

        <button
          class="full-list__download-all flex-h-end relative"
          :class="{'active': isDownloading, 'disabled': isDashboardLoading}"
          :aria-disabled="isDownloading || isDashboardLoading"
          title="Download CSV for all data"
          @click="downloadCSV"
        >
          {{ translations.download_full_list }}
          <div
            v-if="isDownloading"
            class="loader"
          />
        </button>
      </div>

      <div class="full-list__header flex flex-v-center relative">
        <span class="full-list__col--1">
          {{ componentItemType }}
        </span>
        <span class="full-list__col">
          {{ units }}
        </span>
      </div>

      <ul class="full-list__rows relative">
        <li
          v-for="(item, index) in items"
          :key="getVForKey('item', index)"
          class="full-list__row flex flex-v-center"
        >
          <span
            class="full-list__col--1 flex-no-shrink"
            :class="labelClass"
          >
            {{ item.name }}
          </span>
          <span class="full-list__col">
            {{ getFormattedValue(item.value) }}
          </span>
        </li>
        <component-loading-overlay
          v-if="showOverlay"
          :loading="isFullListLoading"
          :error="hasFullListError"
        />
      </ul>

      <pagination
        class="full-list__pagination"
        :disabled="isDashboardLoading"
        :metadata="metadata"
        @change-page="onChangePage"
      />
    </div>
  </modal>
</template>

<script>
import Pagination from '../pagination/Pagination'
import Modal from '../modal/Modal'
import ComponentLoadingOverlay from '../component/ComponentLoadingOverlay'
import mixinIds from '../../mixins/mixin-ids'
import mixinComponentLoadingOverlay from '../../mixins/mixin-component-loading-overlay'
import mixinStoreExplore from '../../mixins/mixin-store-explore'
import { fetchComponentData, fetchComponentCSV, downloadCSV, LOADING_STATES } from '../../helpers/request-helper'

export default {
  components: { ComponentLoadingOverlay, Modal, Pagination },

  mixins: [mixinIds, mixinComponentLoadingOverlay('items'), mixinStoreExplore],

  data () {
    return {
      items: [],
      params: {},
      units: '',
      metadata: {
        page: 1,
        per_page: 8,
        total: 0
      },
      isActive: false,
      component: {}
    }
  },

  computed: {
    componentId () {
      return this.component ? this.component.id : undefined
    },

    componentTitle () {
      return this.component ? this.component.title : ''
    },

    componentItemType () {
      return this.component ? this.component.item_type : ''
    },

    translations () {
      return this.$store.state.translations.translations.buttons
    },

    isDataSpeciesOrGenus () {
      return ['taxon', 'genus'].indexOf(this.params.taxonomic_level) >= 0
    },

    labelClass () {
      return {
        'italic': this.isDataSpeciesOrGenus
      }
    },

    isDashboardLoading () {
      return this.$store.state.isDashboardLoading
    },

    isDownloading () {
      return this.loadingState === LOADING_STATES.downloadingCsv
    }
  },

  created () {
    this.$eventHub.$on('open-full-list-modal', ({component, params, units}) => {
      this.component = component
      this.params = params
      this.units = units
      this.setData()
      this.openModal()
    })
  },

  methods: {
    onChangePage (page) {
      this.$set(this.metadata, 'page', page)
      this.items = []
      this.setData()
    },

    reset () {
      this.$set(this.metadata, 'page', 1)
      this.$set(this.metadata, 'total', 0)
      this.items = []
    },

    setData () {
      this.updateThisComponentLoadingState(LOADING_STATES.loadingFullList)

      fetchComponentData(
        this.$store.state.translations.locale.id,
        this.componentId,
        {
          ...this.params,
          per_page: this.metadata.per_page,
          page: this.metadata.page
        },
        this.componentRequestSuccessHandler,
        this.componentRequestErrorHandler
      )
    },

    componentRequestSuccessHandler (res) {
      this.metadata.total = res.headers['total-count']
      this.items = res.data
      this.updateThisComponentLoadingState(LOADING_STATES.loaded)
    },

    componentRequestErrorHandler (e) {
      console.log(e)
      this.updateThisComponentLoadingState(LOADING_STATES.errorFullList)
    },

    getFormattedValue (value) {
      return parseInt(value).toLocaleString()
    },

    openModal () {
      this.isActive = true

      if (this.$ga) {
        this.$ga.event('FullList', 'Open', `${window.location.pathname}: ${this.componentId}`)
      }
    },

    closeModal () {
      this.isActive = false
      this.reset()
    },

    downloadCSV () {
      this.updateThisComponentLoadingState(LOADING_STATES.downloadingCsv)

      fetchComponentCSV(
        this.$store.state.translations.locale.id,
        this.componentId,
        this.params,
        this.getOnCSVDownloadSuccess,
        this.getOnCSVDownloadFailure
      )
    },

    getOnCSVDownloadSuccess (res) {
      if (this.$ga) {
        this.$ga.event('FullList', 'Download', `${window.location.pathname}: ${this.componentId}`)
      }

      downloadCSV(res)
      this.updateThisComponentLoadingState(LOADING_STATES.loaded)
    },

    getOnCSVDownloadFailure (err) {
      console.log(err)
      this.updateThisComponentLoadingState(LOADING_STATES.loaded)
    },

    updateThisComponentLoadingState(loadingState) {
      this.updateCompLoadingState(this.component, loadingState)
    }
  }

}
</script>
