export const getNumericCssProperty = (el, property) => {
  const propertyStyle = getElementStyle(el)[property]

  if(propertyStyle.indexOf('rem') !== -1) {
    return convertRem(parseFloat(propertyStyle))
  }

  return parseInt(propertyStyle, 10)
}

const convertRem = value => value * getRootElementFontSize()

//for ie - maybe not the safest method - assumes returns pxs
const getRootElementFontSize = () => 
  parseFloat(getComputedStyle(document.documentElement).fontSize)

const getElementStyle = el =>
  el.currentStyle || window.getComputedStyle(el)

export const addClass = (el, className) => {
  el.className.baseVal += ' ' + className
}

export const removeClass = (el, className) => {
  const regex = new RegExp(` ${className}`, 'g')

  el.className.baseVal = el.className.baseVal.replace(regex, '')
}

export const setBodyScroll = isScrollable => {
  document.querySelector('body').style.overflow = 
  isScrollable ? 'auto' : 'hidden'
}