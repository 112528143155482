<template>
  <!-- eslint-disable vue/attribute-hyphenation -->
  <g>
    <path 
      :d="path" 
      fill="none" 
      :stroke="color.line" 
      stroke-width="1"
    />
    <circle
      v-for="(label, index) in labels"
      :key="getVForKey('marker', index)"
      :cx="label.x"
      :cy="label.y"
      r="8"
      :fill="color.line"
      @mouseover="onMarkerMouseover(label)"
      @mouseleave="onMarkerMouseleave(label)"
    />
  </g>
</template>

<script>
import mixinIds from '../../mixins/mixin-ids'

export default {
  name: 'ChartLineDataset',

  mixins: [mixinIds],

  props: {
    path: {
      type: String,
      required: true
    },
    color: {
      type: Object,
      required: true
    },
    labels: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      markerId: `dot-${this.color.line}`
    }
  },

  methods: {
    onMarkerMouseover (label) {
      this.setMarkerVisibility(label, true)
    },

    onMarkerMouseleave (label) {
      this.setMarkerVisibility(label, false)
    },

    setMarkerVisibility (label, isVisible) {
      this.$emit('toggle-hover-label', isVisible ? this.getLabelWithColor(label) : null)
    },

    getLabelWithColor (label) {
      return {
        ...label,
        color: this.color
      }
    }
  }
}
</script>