<template>
  <div class="chart--row chart flex flex-column flex-h-between">
    <chart-row-bar 
      v-for="(row, index) in rows"
      :key="index"
      class="chart__row"
      :style="{ height: height }"
      :width="percent(row)"
      :label="getRowLabel(row)"
      :number="index + 1"
      :is-label-active="getIsBarLabelActive(index)"
      :color="colorPalette[index]"
    />
  </div>
</template>

<script>
import ChartRowBar from './ChartRowBar.vue'

export default {
  name: 'ChartRow',

  components: { ChartRowBar },

  props: {
    rows: {
      type: Array,
      required: true
    },
    units: {
      type: String,
      default: '%'
    },
    optomiseRowWidth: {
      type: Boolean,
      default: true
    },
    activeLabelIndex: {
      type: Number,
      default: -1
    },
    colorPalette: {
      type: Array,
      required: true
    }
  },

  data () {
    return {
      maxValue: 0
    }
  },

  computed: {
    height () {
      const total = this.rows.length

      return (100/total) - 3 + '%'
    }
  },

  watch: {
    rows () {
      if (this.optomiseRowWidth) {
        this.setMaxValue()
      }    
    }
  },

  created () {
    if (this.optomiseRowWidth) {
      this.setMaxValue()
    }
  },

  methods: {
    getIsBarLabelActive (n) {      
      return n === this.activeLabelIndex
    },

    getRowLabel (row) {
      const value = parseInt(row.value).toLocaleString()

      return value + this.units
    },

    setMaxValue () {
      this.maxValue = Math.max.apply(Math, this.rows.map(row => row.value))
    },

    percent (row) {
      return this.maxValue ? 100 * row.value / this.maxValue : parseFloat(row.value)
    }
  }
}
</script>