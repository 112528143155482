const DEFAULT_COLOR = {
  line: '#000',
  fill: '#000',
  text: '#fff'
}

const DEFAULT_FONT = {
  size: '18',
  weight: '900'
}

export function setOptions ({x, y, text, indexText, color, fontSize, fontWeight}) {
  const mergedColors = {...DEFAULT_COLOR, ...color}

  this.labelWidth = 150
  this.labelHeight = 28
  this.strokeWidth = 2
  this.borderRadius = 3
  this.triangleWidth = 20
  this.triangleHeight = 8

  this.x = x
  this.y = y
  this.text = text
  this.indexText = indexText ? `${indexText}. ` : ''
  this.colourLine = mergedColors.line
  this.colourFill = mergedColors.fill
  this.colourText = mergedColors.text
  this.fontSize = fontSize ? fontSize : DEFAULT_FONT.size
  this.fontWeight = fontWeight ? fontWeight : DEFAULT_FONT.weight
}

export default class ChartDatapointLabel {
  constructor (options) {
    setOptions.bind(this)(options)
  }

  createLabel () {
    return `
    <g transform="translate(0,-30)">
      <rect 
        fill="${this.colourFill}" 
        stroke="${this.colourLine}" 
        stroke-width="${this.strokeWidth}" 
        width="${this.labelWidth}" 
        height="${this.labelHeight}" 
        x="${-this.labelWidth/2}" 
        y="${-this.labelHeight/2}" 
        rx="${this.borderRadius}"
      />
      <text 
        fill="${this.colourText}" 
        text-anchor="middle" 
        style="font-size: ${this.fontSize}px; font-weight: ${this.fontWeight};"
        y="0.35em"
      >
        <tspan style="font-weight: 300;">${this.indexText}</tspan>${this.text}
      </text>
      <rect
        fill="${this.colourFill}"
        width="${this.triangleWidth}"
        height="${this.strokeWidth + 2}"
        x="${-this.triangleWidth/2}"
        y="${this.labelHeight/2-this.strokeWidth/2-1}"
      />
      <polyline 
        points="-${this.triangleWidth/2},${this.labelHeight/2} 0,${this.labelHeight/2 + this.triangleHeight} ${this.triangleWidth/2},${this.labelHeight/2}" 
        fill="${this.colourFill}" 
        stroke="${this.colourLine}" 
        stroke-width="${this.strokeWidth}"
      />
    </g>`
  }
} 