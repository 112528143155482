<template>
  <div 
    :id="componentId" 
    class="component--full-width-chart"
  >
    <component-header
      :title="title"
      :component-id="componentId"
      :has-full-list="false"
      :disabled="showOverlay"
    />
    
    <div class="component__chart-legend-wrapper">
      <div
        class="component__chart"
        :class="{'component__chart--hidden': showOverlay}"
      >
        <chart-line
          :lines="filteredLines"
        />

        <div 
          class="component__disclaimer" 
          v-html="chartSmallprint" 
        />
      </div>

      <component-legend 
        class="component-legend--timeseries"
        :items="lines" 
        :show-keys="true"
        :has-click="true"
        :units="units"
        :selected-index="activeLineIndex"
        index-key="code"
        @item-click="legendItemClick"
      />

      <component-loading-overlay
        v-if="showOverlay"
        :loading="isChartLoading"
        :error="hasChartError"
      />
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import ChartLine from '../charts/ChartLine'
import ComponentHeader from '../component/ComponentHeader'
import ComponentLegend from '../component/ComponentLegend'
import ComponentLoadingOverlay from '../component/ComponentLoadingOverlay'

import mixinComponentLoadingOverlay from '../../mixins/mixin-component-loading-overlay'
import mixinIds from '../../mixins/mixin-ids'
import mixinStoreExplore from '../../mixins/mixin-store-explore'

export default {
  name: 'ChartLineComponent',

  components: { ChartLine, ComponentHeader, ComponentLegend, ComponentLoadingOverlay },

  mixins: [
    mixinComponentLoadingOverlay('lines'),
    mixinIds,
    mixinStoreExplore
  ],

  props: {
    componentId: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: ''
    },
    data: { // {id: String, name: String, value: Number}[]
      type: Array,
      required: true
    },
    colorPalette: {
      type: Array,
      required: true
    },
    xStart: {
      type: Number,
      default: null
    },
    xEnd: {
      type: Number,
      default: null
    },
    units: {
      type: String,
      default: ''
    },
    chartSmallprint: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      activeLineIndex: -1
    }
  },

  computed: {
    lines () {
      const lines = []

      if (
        !this.xStart || 
        !this.xEnd || 
        this.xEnd < this.xStart
      ) { return lines }

      this.data.forEach(line => {
        line.id = `${this.getComponentId()}-${line.id}`
        line.datapoints = this.addMissingDatapoints(line.datapoints)
        lines.push(line)
      })
      
      return lines
    },

    filteredLines () {
      return this.activeLineIndex >= 0 ? 
        this.lines.slice(this.activeLineIndex, this.activeLineIndex + 1) : 
        this.lines
    },
  },

  watch: {
    data () {
      this.resetActiveLineIndex()
    }
  },

  methods: {
    addMissingDatapoints (datapoints) {
      const datapointsCount = this.xEnd - this.xStart + 1
      const newDatapoints = []
      let currentYear = this.xStart
      let datapointsIndex = 0

      for (let i = 0; i < datapointsCount; i++) {
        if (datapoints[datapointsIndex] && datapoints[datapointsIndex].x === currentYear) {
          newDatapoints.push(datapoints[datapointsIndex++])
        } else {
          newDatapoints.push({
            x: currentYear,
            y: 0
          })
        }

        currentYear++
      }

      return newDatapoints
    },

    resetActiveLineIndex () {
      this.activeLineIndex = -1
    },

    legendItemClick (e) {
      this.activeLineIndex === e.index ?
        this.resetActiveLineIndex() :
        this.activeLineIndex = e.index
    }
  },

}
</script>
