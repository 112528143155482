import { polyfill as setObjectAssignPolyfill } from 'es6-object-assign'
import { setLog10Polyfill } from '../polyfills/math-polyfills'
import { setObjectValuesPolyfill } from '../polyfills/object-polyfills'
setObjectAssignPolyfill()
setLog10Polyfill()
setObjectValuesPolyfill()

import Vue from 'vue/dist/vue.esm'
import VueAnalytics from 'vue-analytics'
import Vue2TouchEvents from 'vue2-touch-events'

import Accordion from '../vue-components/accordion/Accordion'
import AccordionItem from '../vue-components/accordion/AccordionItem'
import ChartCircularPackingComponent from '../vue-components/chart-circular-packing-component/ChartCircularPackingComponent'
import ChartDownloadWrapper from '../vue-components/chart-download-wrapper/ChartDownloadWrapper'
import ChartLineComponent from '../vue-components/chart-line-component/ChartLineComponent'
import ChartRowComponent from '../vue-components/chart-row-component/ChartRowComponent'
import Components from '../vue-components/components/Components'
import ExpandableItem from '../vue-components/expandable-item/ExpandableItem'
import FakeTabs from '../vue-components/fake-tabs/FakeTabs'
import Filters from '../vue-components/filters/Filters'
import FullList from '../vue-components/full-list/FullList'
import MapInfographic from '../vue-components/map-infographic/MapInfographic'
import PageTitle from '../vue-components/page-title/PageTitle'
import ScreenOverlay from '../vue-components/screen-overlay/ScreenOverlay'
import Translator from '../vue-components/translator/Translator'

import store from '../store/store'

Vue.prototype.$eventHub = new Vue()

if (window._railsEnv === 'production') {
  Vue.use(VueAnalytics, {
    id: 'UA-129227134-1',
    checkDuplicatedScript: true
  })
}

document.addEventListener('DOMContentLoaded', () => {
  if(document.getElementById('v-app')) {
    Vue.use(Vue2TouchEvents)

    new Vue({
      el: '#v-app',
      store,
      components: {
        Accordion,
        AccordionItem,
        ChartCircularPackingComponent,
        ChartDownloadWrapper,
        ChartLineComponent,
        ChartRowComponent,
        Components,
        ExpandableItem,
        FakeTabs,
        Filters,
        FullList,
        MapInfographic,
        PageTitle,
        ScreenOverlay,
        Translator
      }
    })
  }
})
