<template>
  <div class="component-header">
    <div class="component-header__title-bar flex flex-v-center">
      <h2
        class="component-header__title no-margin"
      >
        {{ title }}
        <span
          v-if="selectedTabLabel"
          class="component-header__title--italic print-only--inline-block"
        >
          {{ translations.by }}
          {{ `${selectedTabLabel}` }}
        </span>
      </h2>
      <component-buttons
        v-if="areButtonsInlineWithTitle"
        :component-id="componentId"
        :disabled="isDisabled"
        :has-full-list="hasFullList"
      />
    </div>

    <div
      v-if="tabs"
      class="component-header__controls-bar"
      :class="{'disabled': isDisabled}"
    >
      <span :class="headerLeftClasses">
        <div class="component-header__tabs-wrapper flex">
          <button
            class="component-header__tab-scroll--left"
            title="See previous tabs"
            :disabled="isDecrementDisabled"
            @click="decrementTab"
          />

          <div class="component-header__tabs-scroll-wrapper">
            <div class="component-header__tabs">
              <button
                v-for="tab in tabs"
                :key="tab.id"
                class="component-header__tab"
                :class="selectedTabClass(tab)"
                :aria-controls="componentId"
                :aria-pressed="isSelectedTab(tab)"
                @click="selectTab(tab)"
              >
                {{ tab.label }}
              </button>
            </div>
          </div>

          <button
            class="component-header__tab-scroll--right"
            title="See next tabs"
            :disabled="isIncrementDisabled"
            @click="incrementTab"
          />
        </div>
      </span>

      <component-buttons
        v-if="!areButtonsInlineWithTitle"
        :component-id="componentId"
        :has-full-list="hasFullList"
      />
    </div>
  </div>
</template>

<script>
import { getNumericCssProperty } from '../../helpers/dom-helper'
import ComponentButtons from './ComponentButtons'

export default {
  components: { ComponentButtons },

  props: {
    componentId: {
      type: String,
      required: true
    },
    tabs: { //{id: String, label: String}[]
      type: Array,
      default: null
    },
    initialTabId: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    isHalfWidth: {
      type: Boolean,
      default: false
    },
    hasFullList: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      selectedTab: this.tabs ? this.tabs[0] : {},
      tabsScrollContainer: null,
      tabsContainer: null,
      isIncrementDisabled: 0,
      currentFirstTabIndex: 0,
      tabWidth: 0,
      tabScrollButtonWidth: 0,
      headerLeftMargin: 0,
      legendWidth: 0
    }
  },

  computed: {
    selectedTabLabel () {
      return this.selectedTab ? this.selectedTab.label : ''
    },

    selectedTabId () {
      return this.selectedTab ? this.selectedTab.id : ''
    },

    areButtonsInlineWithTitle () {
      return !this.tabs || this.isHalfWidth
    },

    headerLeftClasses () {
      return [
        'component-header-left',
        {
          'component-header-left--no-tabs': !this.tabs,
          'component-header-left--scrollable': this.hasScrollableTabs
        }
      ]
    },

    isDecrementDisabled () {
      return this.currentFirstTabIndex === 0
    },

    hasScrollableTabs () {
      return this.tabs && this.tabs.length > 3
    },

    isDashboardLoading () {
      return this.$store.state.isDashboardLoading
    },

    isDisabled () {
      return this.disabled || this.isDashboardLoading
    },

    translations () {
      return this.$store.state.translations.translations.misc
    }
  },

  watch: {
    currentFirstTabIndex () {
      this.shiftTabContainer()
    }
  },

  created () {
    this.initSelectedTab()
    this.updateSelectedTab()
  },

  mounted () {
    if (this.hasScrollableTabs) {
      this.initElements()
      this.resetScrollSizeAndPosition()

      window.addEventListener('resize', this.resetScrollSizeAndPosition)
    }
  },

  methods: {
    decrementTab () {
      this.currentFirstTabIndex--
    },

    incrementTab () {
      this.currentFirstTabIndex++
    },

    setIsIncrementDisabled() {
      if (this.tabsContainer && this.tabsScrollContainer) {
        const tabsContainerLeft = Math.abs(this.tabsContainer.style.left.replace('px', ''))

        this.isIncrementDisabled = tabsContainerLeft >= this.getMaxScroll()
      } else {
        this.isIncrementDisabled = false
      }
    },

    initElements () {
      this.tabsScrollContainer = this.$el.querySelector('.component-header__tabs-scroll-wrapper')
      this.tabsContainer = this.$el.querySelector('.component-header__tabs')
    },

    setDimensionsIfHasTabs () {
      if (this.tabs) {
        this.tabWidth = this.$el.querySelector('.component-header__tab').offsetWidth
        this.tabScrollButtonWidth = this.$el.querySelector('.component-header__tab-scroll--left').offsetWidth
        this.legendWidth = this.$el.querySelector('.component-header__buttons').offsetWidth
        this.headerLeftMargin = getNumericCssProperty(this.$el.querySelector('.component-header-left'), 'marginRight')
      }
    },

    resetScrollSizeAndPosition () {
      this.setDimensionsIfHasTabs()
      this.setTabScrollContainerWidth()
      this.currentFirstTabIndex = 0
      this.setIsIncrementDisabled()
    },

    shiftTabContainer () {
      if (this.currentFirstTabIndex * this.tabWidth > this.getMaxScroll()) {
        this.tabsContainer.style.left = - this.getMaxScroll() + 'px'
      } else {
        this.tabsContainer.style.left = - this.currentFirstTabIndex * this.tabWidth + 'px'
      }

      this.setIsIncrementDisabled()
    },

    getMaxScroll () {
      return this.tabsContainer.offsetWidth - this.tabsScrollContainer.offsetWidth
    },

    setTabScrollContainerWidth () {
      if (this.tabsScrollContainer) {
        let calculatedWidth = this.$el.querySelector('.component-header__controls-bar').offsetWidth -
          2 * this.tabScrollButtonWidth

        if (!this.isHalfWidth) {
          calculatedWidth -= this.headerLeftMargin + this.legendWidth
        }

        this.tabsScrollContainer.style.width = Math.min(calculatedWidth, this.tabWidth * this.tabs.length) + 'px'
      }
    },

    selectTab (tab) {
      this.selectedTab = tab
      this.updateSelectedTab()
    },

    selectedTabClass (tab) {
      return {
        'active': this.isSelectedTab(tab),
        'disabled': this.tabs.length <= 2 //FIXME: temp just to disable for maps as functionality not yet implemented
      }
    },

    isSelectedTab (tab) {
      return this.selectedTabId === tab.id
    },

    initSelectedTab () {
      if (this.initialTabId) {
        this.tabs.forEach(tab => {
          if (tab.id === this.initialTabId) {
            this.selectedTab = tab
          }
        })
      }
    },

    updateSelectedTab () {
      this.$emit('update:selected-tab', this.selectedTabId)
    }
  }
}
</script>
