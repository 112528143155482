<template>
  <div>
    <span 
      v-show="showLabel"
      class="chart__percent flex-vs-center"
      :style="{ 'left': labelLeft ,'border-color': color }"
    >
      <span 
        v-if="number" 
        class="chart__percent-index"
      >
        {{ `${number}.` }}
      </span>
      {{ label.toLocaleString() }}
    </span>

    <span
      class="chart__bar"
      :style="style"
      @mouseover="onBarMouseover()"
      @mouseleave="onBarMouseleave()"
    />
  </div>
</template>

<script>
export default {
  name: 'ChartRowBar',

  props: {
    width: {
      type: Number,
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    number: {
      type: Number,
      default: 0
    },
    isLabelActive: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: 'black'
    }
  }, 

  data () {
    return {
      showLabel: false
    }
  },

  computed: {
    style () {
      return {
        'width': this.widthPercent,
        'min-width': '2px',
        'background': this.color
      }
    },

    widthPercent () {
      return `${this.width}%`
    },

    labelLeft () {
      return `${this.width/2}%`
    }
  },

  watch: {
    isLabelActive (isActive) {
      this.showLabel = isActive
    }
  },

  methods: {
    onBarMouseover () {
      this.showLabel = true
    },

    onBarMouseleave () {
      this.showLabel = false
    },    
  }
}
</script>